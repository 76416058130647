import Creatable from 'react-select/creatable';
import React, { KeyboardEventHandler, useCallback, useEffect, useState } from 'react';
import { components } from 'react-select';

export default function Tagable({
  placeholder,
  defaultValues,
  handleChange,
  onBlur,
}: {
  placeholder: string;
  defaultValues: Array<string>;
  handleChange: (skills: string[]) => void;
  onBlur?: (skills: string[]) => void;
}) {
  const [selectedValues, setSelectedValues] = useState<{ label: string; value: string }[]>([]);
  const [inputFieldValue, setInputFieldValue] = useState<string>('');

  // Initialize selected values based on default values
  useEffect(() => {
    const initialValues = defaultValues.map((value) => ({ label: value, value }));
    setSelectedValues(initialValues);
  }, [defaultValues]);

  // Callback to update the selected values and propagate changes to the parent
  const handleValueChange = useCallback(
    (newValue: any, { action }: { action: string }) => {
      if (action === 'remove-value') {
        const updatedValues = selectedValues.filter((v) => v.value !== newValue.value);
        setSelectedValues(updatedValues);
        handleChange(updatedValues.map((item) => item.value));
      } else {
        setSelectedValues(newValue || []);
        handleChange((newValue || []).map((item: { value: string }) => item.value));
      }
    },
    [selectedValues, handleChange]
  );

  // Handle input changes
  const handleInputChange = useCallback((value: string) => {
    setInputFieldValue(value);
  }, []);

  // Handle "Enter", "Tab", and similar keys to add new values
  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      if (['Enter', 'Tab', ',', ';', ':', '/', '\\'].includes(event.key)) {
        event.preventDefault();
        const trimmedValue = inputFieldValue.trim();

        if (
          trimmedValue &&
          !selectedValues.some((item) => item.value.toLowerCase() === trimmedValue.toLowerCase())
        ) {
          const updatedValues = [...selectedValues, { label: trimmedValue, value: trimmedValue }];
          setSelectedValues(updatedValues);
          handleChange(updatedValues.map((item) => item.value));
        }

        setInputFieldValue('');
      }
    },
    [inputFieldValue, selectedValues, handleChange]
  );

  // Handle blur event
  const handleBlur = useCallback(() => {
    if (onBlur) {
      onBlur(selectedValues.map((item) => item.value));
    }
  }, [selectedValues, onBlur]);

  return (
    <Creatable
      placeholder={placeholder}
      value={selectedValues}
      inputValue={inputFieldValue}
      isMulti
      isClearable={false}
      menuIsOpen={false}
      onChange={handleValueChange}
      onInputChange={handleInputChange}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      styles={{
        container: (base) => ({ ...base }),
        control: (base) => ({
          ...base,
          backgroundColor: 'var(--distro-input-bg)',
          borderColor: 'var(--distro-input-border-color)',
          borderRadius: '0.625rem',
          fontSize: '1rem',
        }),
        multiValue: (base) => ({
          ...base,
          backgroundColor: '#F1F5FE',
          borderRadius: '15px',
        }),
        multiValueLabel: (base) => ({
          ...base,
          color: '#6C7390',
        }),
        multiValueRemove: (base) => ({
          ...base,
          color: '#AAB1CE',
          ':hover': {
            backgroundColor: '#E5E9F2',
            color: '#6C7390',
          },
        }),
      }}
      components={{ DropdownIndicator: null }}
    />
  );
}

const SelectedValuesContainer = ({ isDisabled, getValue, ...props }: any) => {
  const { getOptionValue, formatOptionLabel, onChange } = props.selectProps;
  const getValueLabel = (opt: any) => formatOptionLabel?.(opt, 'value') || opt.label;
  const getKey = (opt: any, index: any) => `${getOptionValue(opt)}-${index}`;

  const toMultiValue = (opt: any, index: any) => (
    <components.MultiValue
      getValue={getValue}
      {...props}
      components={{
        Container: components.MultiValueContainer,
        Label: components.MultiValueLabel,
        Remove: components.MultiValueRemove,
      }}
      isDisabled={isDisabled}
      key={getKey(opt, index)}
      index={index}
      removeProps={{
        onClick: () => onChange(opt, { action: 'remove-value' }),
        onTouchEnd: () => onChange(opt, { action: 'remove-value' }),
        onMouseDown: (e) => {
          e.preventDefault();
          e.stopPropagation();
        },
      }}
      data={opt}
    >
      {getValueLabel(opt)}
    </components.MultiValue>
  );

  return (
    <div style={{ margin: '.5rem 0 1rem', display: 'flex', flexFlow: 'row wrap' }}>
      {getValue().map(toMultiValue)}
    </div>
  );
};

const SelectContainer = ({ children, className, innerProps, isFocused, ...commonProps }: any) => {
  const selectContainerProps = {
    ...commonProps,
  };

  return (
    <components.SelectContainer
      className={className}
      innerProps={innerProps}
      isFocused={isFocused}
      {...selectContainerProps}
    >
      <SelectedValuesContainer {...commonProps} />
      {children}
    </components.SelectContainer>
  );
};
