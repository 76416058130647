export const QUESTION_TIMER = 60 * 3; // 3 minutes

export type VetAIInterviewType = {
  ai_interview_id: string;
  candidate_id: string;
  interview_type: string;
  questions: [];
  status: string;
  total_score: number;
  total_score_description: string;
};

export type AiQuestion = {
  questionId: string;
  title: string;
  text: string;
  useTimer: boolean;
  audioURL?: string;
};

export const PRACTICE_QUESTIONS: AiQuestion[] = [
  {
    questionId: 'practice',
    title: 'Hobbies and Interests',
    text: 'What do you enjoy doing in your free time, and how do your hobbies or interests influence your approach to work or problem-solving?',
    useTimer: true,
  },
];

export const DEFAULT_QUESTIONS: AiQuestion[] = [
  {
    questionId: 'default-1',
    title: 'Introducing Distro and our work!',
    text: `Hi, I'm Rebeca,\n\nWelcome to your Distro interview! Before we dive into your skills and experiences, I’d like to take a moment to introduce Distro and explain how it works.\n\nFounded in 2021, Distro is a global platform that connects companies and candidates across more than 200 countries. Our mission is simple: to help both customers and candidates grow and thrive together. But Distro is more than just a connection platform — we’ve designed it to provide a seamless, automated experience throughout the selection, hiring, and payroll process.\n\nThis interview is the first step in that journey. During this process, I’ll be asking questions about your soft skills, hard skills, and previous experiences. You’ll have 3 minutes to answer each question. If you finish early, you can click "Next" to move forward. If you haven’t clicked by the time the 3 minutes are up, the system will automatically take you to the next question. The information you share will help us gather a detailed view of your profile, so our platform can match you with opportunities that align with your strengths.\n\nAs you progress through the selection process and receive an offer, Distro will also streamline your hiring and payroll experience. You’ll be able to manage everything directly from your profile, including accessing your Payment section to easily navigate the payroll system. Our Project Managers and Account Managers will be available to support you throughout, ensuring you have the best possible experience.\n\nWe’re excited to help you take this next step in your career, and we’re here to support you every step of the way!`,

    useTimer: false,
    audioURL:
      'https://app-assets-distro-sandbox-1-us-east-2.s3.us-east-2.amazonaws.com/vetai-welcome.mp3',
  },
];
