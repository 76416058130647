interface Options {
  value: string;
  label: string;
}

export const defaultAvailabilities: string[] = [
  'immediately',
  '1-week',
  '2-weeks',
  '1-month',
  'part-time',
  'not-currently',
];

export const startAvailabityOptions: Array<Options> = [
  {
    value: 'immediately',
    label: 'Immediately Available',
  },
  {
    value: '1-week',
    label: '1 Week Notice',
  },
  {
    value: '2-weeks',
    label: '2 Weeks Notice',
  },
  {
    value: '1-month',
    label: '1 Month Notice',
  },
  {
    value: 'part-time',
    label: 'Available Part-Time',
  },
  {
    value: 'not-currently',
    label: 'Not Currently Available',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

export const getAvailability = (availability: string | undefined): string => {
  if (availability) {
    const found = startAvailabityOptions.find((option) => option.value === availability);
    if (found) {
      return found.label;
    }

    return availability;
  }
  return '';
};
