interface T {
  handleClose: () => void;
  handleStart: () => void;
}

export const AreYouReady = ({ handleClose, handleStart }: T) => {
  return (
    <div className="flex flex-col items-center px-8 py-2">
      <h3 className="text-h3 font-extrabold mt-8">Are you ready?</h3>
      <p className="text-body14 text-center">
        Hit start, and our AI Interviewer will take it from here. The interview should take about 10
        minutes to complete, and once you're finished, a real person will review your video.
      </p>
      <p className="text-body14 text-center">
        You’ll have 2 attempts to complete the interview. If you're not happy with your answers, you
        can cancel and restart—just keep in mind you only have two tries, so make sure you're
        confident before closing!
      </p>
      <p className="text-body14 text-center">Share as much as you feel comfortable with!</p>

      <div className="flex gap-x-2 mb-8">
        <button
          onClick={handleClose}
          className="btn btn-light btn-active-light !text-darkGray !rounded-[12px]"
        >
          Close
        </button>

        <button
          onClick={handleStart}
          className="btn btn-light btn-active-light main-gradient !text-white !rounded-[12px]"
        >
          Start
        </button>
      </div>
    </div>
  );
};
