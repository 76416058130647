import axios from 'axios';
import { UserModel } from './_models';
import * as Sentry from '@sentry/react';
import { Config } from '../../../../config';

const API_URL = Config.api_url;

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/ping`;
export const LOGIN_URL = `${API_URL}/auth/login`;
export const LOGOUT_URL = `${API_URL}/auth/logout`;
export const REGISTER_URL = `${API_URL}/auth`;
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot-password`;
export const RESET_PASSWORD_URL = `${API_URL}/reset-password`;
export const VERIFY_EMAIL_URL = `${API_URL}/auth/verify/email`;
export const RESET_EMAIL_VERIFICATION_URL = `${API_URL}/auth/verify/resend`;

export interface AuthResponse {
  value: {
    authorization: {
      access_token: string;
      expires_in: number;
    };
    user: UserModel;
  };
}

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthResponse>(LOGIN_URL, {
    email,
    password,
  });
}

export function resendEmailVerification(email: string): Promise<any> {
  return axios.post(RESET_EMAIL_VERIFICATION_URL, { email });
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  type: 'customer' | 'candidate',
  organization_name?: string,
  recruiter?: string | null
) {
  const formData = new FormData();

  formData.append('email', email);
  formData.append('type', type);
  formData.append('first_name', firstname);
  formData.append('last_name', lastname);
  formData.append('password', password);

  if (type == 'customer') {
    formData.append('organization_name', organization_name as string);
  }

  if (type == 'candidate') {
    if (recruiter) {
      formData.append('recruiter', recruiter);
    }
  }

  return axios.post(REGISTER_URL, formData);
}

export function logoutUser() {
  try {
    return axios.post(LOGOUT_URL);
  } catch (error) {
    Sentry.captureException(error);
  }
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email,
  });
}

export function resetPassword(
  email: string,
  password: string,
  confirmPassword: string,
  token: string | null
) {
  return axios.post<{ result: boolean }>(RESET_PASSWORD_URL, {
    email,
    password,
    password_confirmation: confirmPassword,
    token,
  });
}

export function verifyEmail(token: string) {
  return axios.get<{ result: boolean }>(`${VERIFY_EMAIL_URL}/${token}`);
}

export function getUserByToken() {
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL);
}
