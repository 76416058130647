interface Options {
  value: number;
  label: number;
}

const arrayRange = (start: number, stop: number, step: number) =>
  Array.from({ length: (stop - start) / step + 1 }, (value, index: number) => {
    return { value: index, label: index };
  });

export const YearsExperience: Array<Options> = arrayRange(0, 30, 1);

export const findYearsExperienceByValue = (value: number) =>
  YearsExperience.find((o: any) => o.value == value);
