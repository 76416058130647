interface Options {
  value: string;
  label: string;
}

export const SalaryRanges: Array<Options> = [
  {
    value: '50000-150000',
    label: '$500-$1500',
  },
  {
    value: '150100-250000',
    label: '$1,501-$2,500',
  },
  {
    value: '250100-350000',
    label: '$2,501-$3,500',
  },
  {
    value: '350100-450000',
    label: '$3,501-$4,500',
  },
  {
    value: '450100-550000',
    label: '$4,501-$5,500',
  },
  {
    value: '550100-650000',
    label: '$5,501-$6,500',
  },
  {
    value: '650100-750000',
    label: '$6,501-$7,500',
  },
  {
    value: '750100-850000',
    label: '$7,501-$8,500',
  },
  {
    value: '850100-950000',
    label: '$8,501-$9,500',
  },
  {
    value: '950100-1050000',
    label: '$9,501-$10,500',
  },
  {
    value: '1050100-1150000',
    label: '$10,501-$11,500',
  },
  {
    value: '1150100-1250000',
    label: '$11,501-$12,500',
  },
  {
    value: '1250100-1350000',
    label: '$12,501-$13,500',
  },
  {
    value: '1350100-1450000',
    label: '$13,501-$14,500',
  },
  {
    value: '1450100-1550000',
    label: '$14,501-$15,500',
  },
  {
    value: '1550000+',
    label: '$15,501+',
  },
];

export const findSalaryRangeByValue = (value: string) =>
  SalaryRanges.find((o) => o.value === value);
export const findSalaryRangeLabelByValue = (value: string) => findSalaryRangeByValue(value)?.label;
