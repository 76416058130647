interface T {
  handleClose: () => void;
  missingFields: string[];
}

export const MissingProfile = ({ handleClose, missingFields }: T) => {
  return (
    <div className="flex flex-col items-center">
      <img
        src="/media/icons/missing_profile.png"
        alt="Missing Profile Info"
        className="max-w-[60px]"
      />

      <h3 className="text-h3 font-extrabold mt-8">Oops, it looks like something’s missing!</h3>
      <p className="text-body14 text-center">
        Please make sure your profile is complete and up-to-date before starting your video.
      </p>

      <p className="text-body14">
        Missing Fields: <span className="font-bold">{missingFields.join(', ')}</span>
      </p>

      <button
        onClick={handleClose}
        className="btn btn-light btn-active-light !text-darkGray !rounded-[12px]"
      >
        Close
      </button>
    </div>
  );
};
