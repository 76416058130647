import { useEffect, useState } from 'react';

interface T {
  handleClose: () => void;
  handleStart: () => void;
  handleStartPractice: () => void;
}

export const StartInterview = ({ handleClose, handleStart, handleStartPractice }: T) => {
  const [permissionGranted, setPermissionGranted] = useState(false);
  const [error, setError] = useState(false);
  const [isChrome, setIsChrome] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    // Check for Chrome but exclude Edge and Opera
    const isGoogleChrome =
      /Chrome/.test(userAgent) && !/Edg/.test(userAgent) && !/OPR/.test(userAgent);

    setIsChrome(isGoogleChrome);
  }, []);

  const requestMediaAccess = async () => {
    try {
      // Request camera and microphone permissions
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      setPermissionGranted(true);
      setError(false);

      // Stop all tracks after granting access
      stream.getTracks().forEach((track) => track.stop());
    } catch (err) {
      setError(true);
      console.error(err);
    }
  };

  const showStatusIcon = (status: boolean) =>
    status ? (
      <i className="bi bi-check-circle-fill !text-green"></i>
    ) : (
      <i className="bi bi-exclamation-triangle-fill !text-orange"></i>
    );

  return (
    <div className="flex flex-col items-center text-center">
      <h3 className="text-h3 font-extrabold">Meet Rebeca, your AI Assistant!</h3>

      <img src="/media/rebeca.png" alt="Rebeca" className="rounded-full w-[120px] my-4" />

      <p className="text-body14">
        She’s here to guide you through your video introduction with a mix of behavioral and
        technical questions.
      </p>
      <p className="text-body14">
        You'll have 2 attempts to complete the interview, so no need to stress!
      </p>
      <p className="text-body14">Let's start with a quick practice round to get you comfortable!</p>
      <p className="text-body14 text-center">
        For the best experience, we recommend using <span className="font-bold">Google Chrome</span>{' '}
        as your browser {showStatusIcon(isChrome)}
      </p>

      <p className="text-body14 text-center">
        Please make sure to allow the browser to{' '}
        <span className="font-bold">access your camera and microphone</span> before starting{' '}
        {showStatusIcon(permissionGranted)}
      </p>

      <div className="flex gap-2">
        <button
          onClick={handleClose}
          className="btn btn-light btn-active-light !text-darkGray !rounded-[12px]"
        >
          Cancel
        </button>

        {!permissionGranted ? (
          <button
            onClick={requestMediaAccess}
            className="btn btn-primary !text-white !rounded-[12px]"
          >
            Enable Camera & Mic
          </button>
        ) : (
          <>
            <button
              onClick={handleStartPractice}
              className="btn main-gradient !text-white !rounded-[12px]"
            >
              Start Practice
            </button>
            <button
              onClick={handleStart}
              className="btn btn-outline-primary !rounded-[12px] !text-pri hover:!text-white"
            >
              Skip Practice
            </button>
          </>
        )}
      </div>

      {error && (
        <p className="text-body14 !font-bold text-alert mt-2 mb-0">
          Permission Denied. Please reset the permissions and allow the camera and mic to continue.
        </p>
      )}
    </div>
  );
};
