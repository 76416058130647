import { TIMEZONES } from './timezone';

export interface CountriesItem {
  value: string;
  label: string;
  flag: string;
  flags: Array<string>;
  timezones: Array<string>;
}

// SEE: https://restcountries.com/v3.1/all
export const COUNTRIES: Array<CountriesItem | any> = [
  {
    value: 'Afghanistan',
    label: 'Afghanistan',
    flag: '🇦🇫',
    flags: [
      'https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_the_Taliban.svg',
      'https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Flag_of_the_Taliban.svg/320px-Flag_of_the_Taliban.svg.png',
    ],
    timezones: ['UTC+04:30'],
  },
  {
    value: 'Åland Islands',
    label: 'Åland Islands',
    flag: '🇦🇽',
    flags: ['https://flagcdn.com/ax.svg', 'https://flagcdn.com/w320/ax.png'],
    timezones: ['UTC+02:00'],
  },
  {
    value: 'Albania',
    label: 'Albania',
    flag: '🇦🇱',
    flags: ['https://flagcdn.com/al.svg', 'https://flagcdn.com/w320/al.png'],
    timezones: ['UTC+01:00'],
  },
  {
    value: 'Algeria',
    label: 'Algeria',
    flag: '🇩🇿',
    flags: ['https://flagcdn.com/dz.svg', 'https://flagcdn.com/w320/dz.png'],
    timezones: ['UTC+01:00'],
  },
  {
    value: 'American Samoa',
    label: 'American Samoa',
    flag: '🇦🇸',
    flags: ['https://flagcdn.com/as.svg', 'https://flagcdn.com/w320/as.png'],
    timezones: ['UTC-11:00'],
  },
  {
    value: 'Andorra',
    label: 'Andorra',
    flag: '🇦🇩',
    flags: ['https://flagcdn.com/ad.svg', 'https://flagcdn.com/w320/ad.png'],
    timezones: ['UTC+01:00'],
  },
  {
    value: 'Angola',
    label: 'Angola',
    flag: '🇦🇴',
    flags: ['https://flagcdn.com/ao.svg', 'https://flagcdn.com/w320/ao.png'],
    timezones: ['UTC+01:00'],
  },
  {
    value: 'Anguilla',
    label: 'Anguilla',
    flag: '🇦🇮',
    flags: ['https://flagcdn.com/ai.svg', 'https://flagcdn.com/w320/ai.png'],
    timezones: ['UTC-04:00'],
  },
  {
    value: 'Antarctica',
    label: 'Antarctica',
    flag: '🇦🇶',
    flags: ['https://flagcdn.com/aq.svg', 'https://flagcdn.com/w320/aq.png'],
    timezones: [
      'UTC-03:00',
      'UTC+03:00',
      'UTC+05:00',
      'UTC+06:00',
      'UTC+07:00',
      'UTC+08:00',
      'UTC+10:00',
      'UTC+12:00',
    ],
  },
  {
    value: 'Antigua and Barbuda',
    label: 'Antigua and Barbuda',
    flag: '🇦🇬',
    flags: ['https://flagcdn.com/ag.svg', 'https://flagcdn.com/w320/ag.png'],
    timezones: ['UTC-04:00'],
  },
  {
    value: 'Argentina',
    label: 'Argentina',
    flag: '🇦🇷',
    flags: ['https://flagcdn.com/ar.svg', 'https://flagcdn.com/w320/ar.png'],
    timezones: ['UTC-03:00'],
  },
  {
    value: 'Armenia',
    label: 'Armenia',
    flag: '🇦🇲',
    flags: ['https://flagcdn.com/am.svg', 'https://flagcdn.com/w320/am.png'],
    timezones: ['UTC+04:00'],
  },
  {
    value: 'Aruba',
    label: 'Aruba',
    flag: '🇦🇼',
    flags: ['https://flagcdn.com/aw.svg', 'https://flagcdn.com/w320/aw.png'],
    timezones: ['UTC-04:00'],
  },
  {
    value: 'Australia',
    label: 'Australia',
    flag: '🇦🇺',
    flags: ['https://flagcdn.com/au.svg', 'https://flagcdn.com/w320/au.png'],
    timezones: [
      'UTC+05:00',
      'UTC+06:30',
      'UTC+07:00',
      'UTC+08:00',
      'UTC+09:30',
      'UTC+10:00',
      'UTC+10:30',
      'UTC+11:30',
    ],
  },
  {
    value: 'Austria',
    label: 'Austria',
    flag: '🇦🇹',
    flags: ['https://flagcdn.com/at.svg', 'https://flagcdn.com/w320/at.png'],
    timezones: ['UTC+01:00'],
  },
  {
    value: 'Azerbaijan',
    label: 'Azerbaijan',
    flag: '🇦🇿',
    flags: ['https://flagcdn.com/az.svg', 'https://flagcdn.com/w320/az.png'],
    timezones: ['UTC+04:00'],
  },
  {
    value: 'Bahamas',
    label: 'Bahamas',
    flag: '🇧🇸',
    flags: ['https://flagcdn.com/bs.svg', 'https://flagcdn.com/w320/bs.png'],
    timezones: ['UTC-05:00'],
  },
  {
    value: 'Bahrain',
    label: 'Bahrain',
    flag: '🇧🇭',
    flags: ['https://flagcdn.com/bh.svg', 'https://flagcdn.com/w320/bh.png'],
    timezones: ['UTC+03:00'],
  },
  {
    value: 'Bangladesh',
    label: 'Bangladesh',
    flag: '🇧🇩',
    flags: ['https://flagcdn.com/bd.svg', 'https://flagcdn.com/w320/bd.png'],
    timezones: ['UTC+06:00'],
  },
  {
    value: 'Barbados',
    label: 'Barbados',
    flag: '🇧🇧',
    flags: ['https://flagcdn.com/bb.svg', 'https://flagcdn.com/w320/bb.png'],
    timezones: ['UTC-04:00'],
  },
  {
    value: 'Belarus',
    label: 'Belarus',
    flag: '🇧🇾',
    flags: ['https://flagcdn.com/by.svg', 'https://flagcdn.com/w320/by.png'],
    timezones: ['UTC+03:00'],
  },
  {
    value: 'Belgium',
    label: 'Belgium',
    flag: '🇧🇪',
    flags: ['https://flagcdn.com/be.svg', 'https://flagcdn.com/w320/be.png'],
    timezones: ['UTC+01:00'],
  },
  {
    value: 'Belize',
    label: 'Belize',
    flag: '🇧🇿',
    flags: ['https://flagcdn.com/bz.svg', 'https://flagcdn.com/w320/bz.png'],
    timezones: ['UTC-06:00'],
  },
  {
    value: 'Benin',
    label: 'Benin',
    flag: '🇧🇯',
    flags: ['https://flagcdn.com/bj.svg', 'https://flagcdn.com/w320/bj.png'],
    timezones: ['UTC+01:00'],
  },
  {
    value: 'Bermuda',
    label: 'Bermuda',
    flag: '🇧🇲',
    flags: ['https://flagcdn.com/bm.svg', 'https://flagcdn.com/w320/bm.png'],
    timezones: ['UTC-04:00'],
  },
  {
    value: 'Bhutan',
    label: 'Bhutan',
    flag: '🇧🇹',
    flags: ['https://flagcdn.com/bt.svg', 'https://flagcdn.com/w320/bt.png'],
    timezones: ['UTC+06:00'],
  },
  {
    value: 'Bolivia',
    label: 'Bolivia',
    flag: '🇧🇴',
    flags: ['https://flagcdn.com/bo.svg', 'https://flagcdn.com/w320/bo.png'],
    timezones: ['UTC-04:00'],
  },
  {
    value: 'Bosnia and Herzegovina',
    label: 'Bosnia and Herzegovina',
    flag: '🇧🇦',
    flags: ['https://flagcdn.com/ba.svg', 'https://flagcdn.com/w320/ba.png'],
    timezones: ['UTC+01:00'],
  },
  {
    value: 'Botswana',
    label: 'Botswana',
    flag: '🇧🇼',
    flags: ['https://flagcdn.com/bw.svg', 'https://flagcdn.com/w320/bw.png'],
    timezones: ['UTC+02:00'],
  },
  {
    value: 'Bouvet Island',
    label: 'Bouvet Island',
    flag: '🇧🇻',
    flags: ['https://flagcdn.com/bv.svg', 'https://flagcdn.com/w320/bv.png'],
    timezones: ['UTC+01:00'],
  },
  {
    value: 'Brazil',
    label: 'Brazil',
    flag: '🇧🇷',
    flags: ['https://flagcdn.com/br.svg', 'https://flagcdn.com/w320/br.png'],
    timezones: ['UTC-05:00', 'UTC-04:00', 'UTC-03:00', 'UTC-02:00'],
  },
  {
    value: 'British Indian Ocean Territory',
    label: 'British Indian Ocean Territory',
    flag: '🇮🇴',
    flags: ['https://flagcdn.com/io.svg', 'https://flagcdn.com/w320/io.png'],
    timezones: ['UTC+06:00'],
  },
  {
    value: 'British Virgin Islands',
    label: 'British Virgin Islands',
    flag: '🇻🇬',
    flags: ['https://flagcdn.com/vg.svg', 'https://flagcdn.com/w320/vg.png'],
    timezones: ['UTC-04:00'],
  },
  {
    value: 'Brunei',
    label: 'Brunei',
    flag: '🇧🇳',
    flags: ['https://flagcdn.com/bn.svg', 'https://flagcdn.com/w320/bn.png'],
    timezones: ['UTC+08:00'],
  },
  {
    value: 'Bulgaria',
    label: 'Bulgaria',
    flag: '🇧🇬',
    flags: ['https://flagcdn.com/bg.svg', 'https://flagcdn.com/w320/bg.png'],
    timezones: ['UTC+02:00'],
  },
  {
    value: 'Burkina Faso',
    label: 'Burkina Faso',
    flag: '🇧🇫',
    flags: ['https://flagcdn.com/bf.svg', 'https://flagcdn.com/w320/bf.png'],
    timezones: ['UTC'],
  },
  {
    value: 'Burundi',
    label: 'Burundi',
    flag: '🇧🇮',
    flags: ['https://flagcdn.com/bi.svg', 'https://flagcdn.com/w320/bi.png'],
    timezones: ['UTC+02:00'],
  },
  {
    value: 'Cambodia',
    label: 'Cambodia',
    flag: '🇰🇭',
    flags: ['https://flagcdn.com/kh.svg', 'https://flagcdn.com/w320/kh.png'],
    timezones: ['UTC+07:00'],
  },
  {
    value: 'Cameroon',
    label: 'Cameroon',
    flag: '🇨🇲',
    flags: ['https://flagcdn.com/cm.svg', 'https://flagcdn.com/w320/cm.png'],
    timezones: ['UTC+01:00'],
  },
  {
    value: 'Canada',
    label: 'Canada',
    flag: '🇨🇦',
    flags: ['https://flagcdn.com/ca.svg', 'https://flagcdn.com/w320/ca.png'],
    timezones: ['UTC-08:00', 'UTC-07:00', 'UTC-06:00', 'UTC-05:00', 'UTC-04:00', 'UTC-03:30'],
  },
  {
    value: 'Cape Verde',
    label: 'Cape Verde',
    flag: '🇨🇻',
    flags: ['https://flagcdn.com/cv.svg', 'https://flagcdn.com/w320/cv.png'],
    timezones: ['UTC-01:00'],
  },
  {
    value: 'Caribbean Netherlands',
    label: 'Caribbean Netherlands',
    flag: '',
    flags: ['https://flagcdn.com/bq.svg', 'https://flagcdn.com/w320/bq.png'],
    timezones: ['UTC-04:00'],
  },
  {
    value: 'Cayman Islands',
    label: 'Cayman Islands',
    flag: '🇰🇾',
    flags: ['https://flagcdn.com/ky.svg', 'https://flagcdn.com/w320/ky.png'],
    timezones: ['UTC-05:00'],
  },
  {
    value: 'Central African Republic',
    label: 'Central African Republic',
    flag: '🇨🇫',
    flags: ['https://flagcdn.com/cf.svg', 'https://flagcdn.com/w320/cf.png'],
    timezones: ['UTC+01:00'],
  },
  {
    value: 'Chad',
    label: 'Chad',
    flag: '🇹🇩',
    flags: ['https://flagcdn.com/td.svg', 'https://flagcdn.com/w320/td.png'],
    timezones: ['UTC+01:00'],
  },
  {
    value: 'Chile',
    label: 'Chile',
    flag: '🇨🇱',
    flags: ['https://flagcdn.com/cl.svg', 'https://flagcdn.com/w320/cl.png'],
    timezones: ['UTC-06:00', 'UTC-04:00'],
  },
  {
    value: 'China',
    label: 'China',
    flag: '🇨🇳',
    flags: ['https://flagcdn.com/cn.svg', 'https://flagcdn.com/w320/cn.png'],
    timezones: ['UTC+08:00'],
  },
  {
    value: 'Christmas Island',
    label: 'Christmas Island',
    flag: '🇨🇽',
    flags: ['https://flagcdn.com/cx.svg', 'https://flagcdn.com/w320/cx.png'],
    timezones: ['UTC+07:00'],
  },
  {
    value: 'Cocos (Keeling) Islands',
    label: 'Cocos (Keeling) Islands',
    flag: '🇨🇨',
    flags: ['https://flagcdn.com/cc.svg', 'https://flagcdn.com/w320/cc.png'],
    timezones: ['UTC+06:30'],
  },
  {
    value: 'Colombia',
    label: 'Colombia',
    flag: '🇨🇴',
    flags: ['https://flagcdn.com/co.svg', 'https://flagcdn.com/w320/co.png'],
    timezones: ['UTC-05:00'],
  },
  {
    value: 'Comoros',
    label: 'Comoros',
    flag: '🇰🇲',
    flags: ['https://flagcdn.com/km.svg', 'https://flagcdn.com/w320/km.png'],
    timezones: ['UTC+03:00'],
  },
  {
    value: 'Cook Islands',
    label: 'Cook Islands',
    flag: '🇨🇰',
    flags: ['https://flagcdn.com/ck.svg', 'https://flagcdn.com/w320/ck.png'],
    timezones: ['UTC-10:00'],
  },
  {
    value: 'Costa Rica',
    label: 'Costa Rica',
    flag: '🇨🇷',
    flags: ['https://flagcdn.com/cr.svg', 'https://flagcdn.com/w320/cr.png'],
    timezones: ['UTC-06:00'],
  },
  {
    value: 'Croatia',
    label: 'Croatia',
    flag: '🇭🇷',
    flags: ['https://flagcdn.com/hr.svg', 'https://flagcdn.com/w320/hr.png'],
    timezones: ['UTC+01:00'],
  },
  {
    value: 'Cuba',
    label: 'Cuba',
    flag: '🇨🇺',
    flags: ['https://flagcdn.com/cu.svg', 'https://flagcdn.com/w320/cu.png'],
    timezones: ['UTC-05:00'],
  },
  {
    value: 'Curaçao',
    label: 'Curaçao',
    flag: '🇨🇼',
    flags: ['https://flagcdn.com/cw.svg', 'https://flagcdn.com/w320/cw.png'],
    timezones: ['UTC-04:00'],
  },
  {
    value: 'Cyprus',
    label: 'Cyprus',
    flag: '🇨🇾',
    flags: ['https://flagcdn.com/cy.svg', 'https://flagcdn.com/w320/cy.png'],
    timezones: ['UTC+02:00'],
  },
  {
    value: 'Czechia',
    label: 'Czechia',
    flag: '🇨🇿',
    flags: ['https://flagcdn.com/cz.svg', 'https://flagcdn.com/w320/cz.png'],
    timezones: ['UTC+01:00'],
  },
  {
    value: 'Denmark',
    label: 'Denmark',
    flag: '🇩🇰',
    flags: ['https://flagcdn.com/dk.svg', 'https://flagcdn.com/w320/dk.png'],
    timezones: ['UTC-04:00', 'UTC-03:00', 'UTC-01:00', 'UTC', 'UTC+01:00'],
  },
  {
    value: 'Djibouti',
    label: 'Djibouti',
    flag: '🇩🇯',
    flags: ['https://flagcdn.com/dj.svg', 'https://flagcdn.com/w320/dj.png'],
    timezones: ['UTC+03:00'],
  },
  {
    value: 'Dominica',
    label: 'Dominica',
    flag: '🇩🇲',
    flags: ['https://flagcdn.com/dm.svg', 'https://flagcdn.com/w320/dm.png'],
    timezones: ['UTC-04:00'],
  },
  {
    value: 'Dominican Republic',
    label: 'Dominican Republic',
    flag: '🇩🇴',
    flags: ['https://flagcdn.com/do.svg', 'https://flagcdn.com/w320/do.png'],
    timezones: ['UTC-04:00'],
  },
  {
    value: 'DR Congo',
    label: 'DR Congo',
    flag: '🇨🇩',
    flags: ['https://flagcdn.com/cd.svg', 'https://flagcdn.com/w320/cd.png'],
    timezones: ['UTC+01:00', 'UTC+02:00'],
  },
  {
    value: 'Ecuador',
    label: 'Ecuador',
    flag: '🇪🇨',
    flags: ['https://flagcdn.com/ec.svg', 'https://flagcdn.com/w320/ec.png'],
    timezones: ['UTC-06:00', 'UTC-05:00'],
  },
  {
    value: 'Egypt',
    label: 'Egypt',
    flag: '🇪🇬',
    flags: ['https://flagcdn.com/eg.svg', 'https://flagcdn.com/w320/eg.png'],
    timezones: ['UTC+02:00'],
  },
  {
    value: 'El Salvador',
    label: 'El Salvador',
    flag: '🇸🇻',
    flags: ['https://flagcdn.com/sv.svg', 'https://flagcdn.com/w320/sv.png'],
    timezones: ['UTC-06:00'],
  },
  {
    value: 'Equatorial Guinea',
    label: 'Equatorial Guinea',
    flag: '🇬🇶',
    flags: ['https://flagcdn.com/gq.svg', 'https://flagcdn.com/w320/gq.png'],
    timezones: ['UTC+01:00'],
  },
  {
    value: 'Eritrea',
    label: 'Eritrea',
    flag: '🇪🇷',
    flags: ['https://flagcdn.com/er.svg', 'https://flagcdn.com/w320/er.png'],
    timezones: ['UTC+03:00'],
  },
  {
    value: 'Estonia',
    label: 'Estonia',
    flag: '🇪🇪',
    flags: ['https://flagcdn.com/ee.svg', 'https://flagcdn.com/w320/ee.png'],
    timezones: ['UTC+02:00'],
  },
  {
    value: 'Eswatini',
    label: 'Eswatini',
    flag: '🇸🇿',
    flags: ['https://flagcdn.com/sz.svg', 'https://flagcdn.com/w320/sz.png'],
    timezones: ['UTC+02:00'],
  },
  {
    value: 'Ethiopia',
    label: 'Ethiopia',
    flag: '🇪🇹',
    flags: ['https://flagcdn.com/et.svg', 'https://flagcdn.com/w320/et.png'],
    timezones: ['UTC+03:00'],
  },
  {
    value: 'Falkland Islands',
    label: 'Falkland Islands',
    flag: '🇫🇰',
    flags: ['https://flagcdn.com/fk.svg', 'https://flagcdn.com/w320/fk.png'],
    timezones: ['UTC-04:00'],
  },
  {
    value: 'Faroe Islands',
    label: 'Faroe Islands',
    flag: '🇫🇴',
    flags: ['https://flagcdn.com/fo.svg', 'https://flagcdn.com/w320/fo.png'],
    timezones: ['UTC+00:00'],
  },
  {
    value: 'Fiji',
    label: 'Fiji',
    flag: '🇫🇯',
    flags: ['https://flagcdn.com/fj.svg', 'https://flagcdn.com/w320/fj.png'],
    timezones: ['UTC+12:00'],
  },
  {
    value: 'Finland',
    label: 'Finland',
    flag: '🇫🇮',
    flags: ['https://flagcdn.com/fi.svg', 'https://flagcdn.com/w320/fi.png'],
    timezones: ['UTC+02:00'],
  },
  {
    value: 'France',
    label: 'France',
    flag: '🇫🇷',
    flags: ['https://flagcdn.com/fr.svg', 'https://flagcdn.com/w320/fr.png'],
    timezones: [
      'UTC-10:00',
      'UTC-09:30',
      'UTC-09:00',
      'UTC-08:00',
      'UTC-04:00',
      'UTC-03:00',
      'UTC+01:00',
      'UTC+02:00',
      'UTC+03:00',
      'UTC+04:00',
      'UTC+05:00',
      'UTC+10:00',
      'UTC+11:00',
      'UTC+12:00',
    ],
  },
  {
    value: 'French Guiana',
    label: 'French Guiana',
    flag: '🇬🇫',
    flags: ['https://flagcdn.com/gf.svg', 'https://flagcdn.com/w320/gf.png'],
    timezones: ['UTC-03:00'],
  },
  {
    value: 'French Polynesia',
    label: 'French Polynesia',
    flag: '🇵🇫',
    flags: ['https://flagcdn.com/pf.svg', 'https://flagcdn.com/w320/pf.png'],
    timezones: ['UTC-10:00', 'UTC-09:30', 'UTC-09:00'],
  },
  {
    value: 'French Southern and Antarctic Lands',
    label: 'French Southern and Antarctic Lands',
    flag: '🇹🇫',
    flags: ['https://flagcdn.com/tf.svg', 'https://flagcdn.com/w320/tf.png'],
    timezones: ['UTC+05:00'],
  },
  {
    value: 'Gabon',
    label: 'Gabon',
    flag: '🇬🇦',
    flags: ['https://flagcdn.com/ga.svg', 'https://flagcdn.com/w320/ga.png'],
    timezones: ['UTC+01:00'],
  },
  {
    value: 'Gambia',
    label: 'Gambia',
    flag: '🇬🇲',
    flags: ['https://flagcdn.com/gm.svg', 'https://flagcdn.com/w320/gm.png'],
    timezones: ['UTC+00:00'],
  },
  {
    value: 'Georgia',
    label: 'Georgia',
    flag: '🇬🇪',
    flags: ['https://flagcdn.com/ge.svg', 'https://flagcdn.com/w320/ge.png'],
    timezones: ['UTC-04:00'],
  },
  {
    value: 'Germany',
    label: 'Germany',
    flag: '🇩🇪',
    flags: ['https://flagcdn.com/de.svg', 'https://flagcdn.com/w320/de.png'],
    timezones: ['UTC+01:00'],
  },
  {
    value: 'Ghana',
    label: 'Ghana',
    flag: '🇬🇭',
    flags: ['https://flagcdn.com/gh.svg', 'https://flagcdn.com/w320/gh.png'],
    timezones: ['UTC'],
  },
  {
    value: 'Gibraltar',
    label: 'Gibraltar',
    flag: '🇬🇮',
    flags: ['https://flagcdn.com/gi.svg', 'https://flagcdn.com/w320/gi.png'],
    timezones: ['UTC+01:00'],
  },
  {
    value: 'Greece',
    label: 'Greece',
    flag: '🇬🇷',
    flags: ['https://flagcdn.com/gr.svg', 'https://flagcdn.com/w320/gr.png'],
    timezones: ['UTC+02:00'],
  },
  {
    value: 'Greenland',
    label: 'Greenland',
    flag: '🇬🇱',
    flags: ['https://flagcdn.com/gl.svg', 'https://flagcdn.com/w320/gl.png'],
    timezones: ['UTC-04:00', 'UTC-03:00', 'UTC-01:00', 'UTC+00:00'],
  },
  {
    value: 'Grenada',
    label: 'Grenada',
    flag: '🇬🇩',
    flags: ['https://flagcdn.com/gd.svg', 'https://flagcdn.com/w320/gd.png'],
    timezones: ['UTC-04:00'],
  },
  {
    value: 'Guadeloupe',
    label: 'Guadeloupe',
    flag: '🇬🇵',
    flags: ['https://flagcdn.com/gp.svg', 'https://flagcdn.com/w320/gp.png'],
    timezones: ['UTC-04:00'],
  },
  {
    value: 'Guam',
    label: 'Guam',
    flag: '🇬🇺',
    flags: ['https://flagcdn.com/gu.svg', 'https://flagcdn.com/w320/gu.png'],
    timezones: ['UTC+10:00'],
  },
  {
    value: 'Guatemala',
    label: 'Guatemala',
    flag: '🇬🇹',
    flags: ['https://flagcdn.com/gt.svg', 'https://flagcdn.com/w320/gt.png'],
    timezones: ['UTC-06:00'],
  },
  {
    value: 'Guernsey',
    label: 'Guernsey',
    flag: '🇬🇬',
    flags: ['https://flagcdn.com/gg.svg', 'https://flagcdn.com/w320/gg.png'],
    timezones: ['UTC+00:00'],
  },
  {
    value: 'Guinea',
    label: 'Guinea',
    flag: '🇬🇳',
    flags: ['https://flagcdn.com/gn.svg', 'https://flagcdn.com/w320/gn.png'],
    timezones: ['UTC'],
  },
  {
    value: 'Guinea-Bissau',
    label: 'Guinea-Bissau',
    flag: '🇬🇼',
    flags: ['https://flagcdn.com/gw.svg', 'https://flagcdn.com/w320/gw.png'],
    timezones: ['UTC'],
  },
  {
    value: 'Guyana',
    label: 'Guyana',
    flag: '🇬🇾',
    flags: ['https://flagcdn.com/gy.svg', 'https://flagcdn.com/w320/gy.png'],
    timezones: ['UTC-04:00'],
  },
  {
    value: 'Haiti',
    label: 'Haiti',
    flag: '🇭🇹',
    flags: ['https://flagcdn.com/ht.svg', 'https://flagcdn.com/w320/ht.png'],
    timezones: ['UTC-05:00'],
  },
  {
    value: 'Heard Island and McDonald Islands',
    label: 'Heard Island and McDonald Islands',
    flag: '🇭🇲',
    flags: ['https://flagcdn.com/hm.svg', 'https://flagcdn.com/w320/hm.png'],
    timezones: ['UTC+05:00'],
  },
  {
    value: 'Honduras',
    label: 'Honduras',
    flag: '🇭🇳',
    flags: ['https://flagcdn.com/hn.svg', 'https://flagcdn.com/w320/hn.png'],
    timezones: ['UTC-06:00'],
  },
  {
    value: 'Hong Kong',
    label: 'Hong Kong',
    flag: '🇭🇰',
    flags: ['https://flagcdn.com/hk.svg', 'https://flagcdn.com/w320/hk.png'],
    timezones: ['UTC+08:00'],
  },
  {
    value: 'Hungary',
    label: 'Hungary',
    flag: '🇭🇺',
    flags: ['https://flagcdn.com/hu.svg', 'https://flagcdn.com/w320/hu.png'],
    timezones: ['UTC+01:00'],
  },
  {
    value: 'Iceland',
    label: 'Iceland',
    flag: '🇮🇸',
    flags: ['https://flagcdn.com/is.svg', 'https://flagcdn.com/w320/is.png'],
    timezones: ['UTC'],
  },
  {
    value: 'India',
    label: 'India',
    flag: '🇮🇳',
    flags: ['https://flagcdn.com/in.svg', 'https://flagcdn.com/w320/in.png'],
    timezones: ['UTC+05:30'],
  },
  {
    value: 'Indonesia',
    label: 'Indonesia',
    flag: '🇮🇩',
    flags: ['https://flagcdn.com/id.svg', 'https://flagcdn.com/w320/id.png'],
    timezones: ['UTC+07:00', 'UTC+08:00', 'UTC+09:00'],
  },
  {
    value: 'Iran',
    label: 'Iran',
    flag: '🇮🇷',
    flags: ['https://flagcdn.com/ir.svg', 'https://flagcdn.com/w320/ir.png'],
    timezones: ['UTC+03:30'],
  },
  {
    value: 'Iraq',
    label: 'Iraq',
    flag: '🇮🇶',
    flags: ['https://flagcdn.com/iq.svg', 'https://flagcdn.com/w320/iq.png'],
    timezones: ['UTC+03:00'],
  },
  {
    value: 'Ireland',
    label: 'Ireland',
    flag: '🇮🇪',
    flags: ['https://flagcdn.com/ie.svg', 'https://flagcdn.com/w320/ie.png'],
    timezones: ['UTC'],
  },
  {
    value: 'Isle of Man',
    label: 'Isle of Man',
    flag: '🇮🇲',
    flags: ['https://flagcdn.com/im.svg', 'https://flagcdn.com/w320/im.png'],
    timezones: ['UTC+00:00'],
  },
  {
    value: 'Israel',
    label: 'Israel',
    flag: '🇮🇱',
    flags: ['https://flagcdn.com/il.svg', 'https://flagcdn.com/w320/il.png'],
    timezones: ['UTC+02:00'],
  },
  {
    value: 'Italy',
    label: 'Italy',
    flag: '🇮🇹',
    flags: ['https://flagcdn.com/it.svg', 'https://flagcdn.com/w320/it.png'],
    timezones: ['UTC+01:00'],
  },
  {
    value: 'Ivory Coast',
    label: 'Ivory Coast',
    flag: '🇨🇮',
    flags: ['https://flagcdn.com/ci.svg', 'https://flagcdn.com/w320/ci.png'],
    timezones: ['UTC'],
  },
  {
    value: 'Jamaica',
    label: 'Jamaica',
    flag: '🇯🇲',
    flags: ['https://flagcdn.com/jm.svg', 'https://flagcdn.com/w320/jm.png'],
    timezones: ['UTC-05:00'],
  },
  {
    value: 'Japan',
    label: 'Japan',
    flag: '🇯🇵',
    flags: ['https://flagcdn.com/jp.svg', 'https://flagcdn.com/w320/jp.png'],
    timezones: ['UTC+09:00'],
  },
  {
    value: 'Jersey',
    label: 'Jersey',
    flag: '🇯🇪',
    flags: ['https://flagcdn.com/je.svg', 'https://flagcdn.com/w320/je.png'],
    timezones: ['UTC+01:00'],
  },
  {
    value: 'Jordan',
    label: 'Jordan',
    flag: '🇯🇴',
    flags: ['https://flagcdn.com/jo.svg', 'https://flagcdn.com/w320/jo.png'],
    timezones: ['UTC+03:00'],
  },
  {
    value: 'Kazakhstan',
    label: 'Kazakhstan',
    flag: '🇰🇿',
    flags: ['https://flagcdn.com/kz.svg', 'https://flagcdn.com/w320/kz.png'],
    timezones: ['UTC+05:00', 'UTC+06:00'],
  },
  {
    value: 'Kenya',
    label: 'Kenya',
    flag: '🇰🇪',
    flags: ['https://flagcdn.com/ke.svg', 'https://flagcdn.com/w320/ke.png'],
    timezones: ['UTC+03:00'],
  },
  {
    value: 'Kiribati',
    label: 'Kiribati',
    flag: '🇰🇮',
    flags: ['https://flagcdn.com/ki.svg', 'https://flagcdn.com/w320/ki.png'],
    timezones: ['UTC+12:00', 'UTC+13:00', 'UTC+14:00'],
  },
  {
    value: 'Kosovo',
    label: 'Kosovo',
    flag: '🇽🇰',
    flags: ['https://flagcdn.com/xk.svg', 'https://flagcdn.com/w320/xk.png'],
    timezones: ['UTC+01:00'],
  },
  {
    value: 'Kuwait',
    label: 'Kuwait',
    flag: '🇰🇼',
    flags: ['https://flagcdn.com/kw.svg', 'https://flagcdn.com/w320/kw.png'],
    timezones: ['UTC+03:00'],
  },
  {
    value: 'Kyrgyzstan',
    label: 'Kyrgyzstan',
    flag: '🇰🇬',
    flags: ['https://flagcdn.com/kg.svg', 'https://flagcdn.com/w320/kg.png'],
    timezones: ['UTC+06:00'],
  },
  {
    value: 'Laos',
    label: 'Laos',
    flag: '🇱🇦',
    flags: ['https://flagcdn.com/la.svg', 'https://flagcdn.com/w320/la.png'],
    timezones: ['UTC+07:00'],
  },
  {
    value: 'Latvia',
    label: 'Latvia',
    flag: '🇱🇻',
    flags: ['https://flagcdn.com/lv.svg', 'https://flagcdn.com/w320/lv.png'],
    timezones: ['UTC+02:00'],
  },
  {
    value: 'Lebanon',
    label: 'Lebanon',
    flag: '🇱🇧',
    flags: ['https://flagcdn.com/lb.svg', 'https://flagcdn.com/w320/lb.png'],
    timezones: ['UTC+02:00'],
  },
  {
    value: 'Lesotho',
    label: 'Lesotho',
    flag: '🇱🇸',
    flags: ['https://flagcdn.com/ls.svg', 'https://flagcdn.com/w320/ls.png'],
    timezones: ['UTC+02:00'],
  },
  {
    value: 'Liberia',
    label: 'Liberia',
    flag: '🇱🇷',
    flags: ['https://flagcdn.com/lr.svg', 'https://flagcdn.com/w320/lr.png'],
    timezones: ['UTC'],
  },
  {
    value: 'Libya',
    label: 'Libya',
    flag: '🇱🇾',
    flags: ['https://flagcdn.com/ly.svg', 'https://flagcdn.com/w320/ly.png'],
    timezones: ['UTC+01:00'],
  },
  {
    value: 'Liechtenstein',
    label: 'Liechtenstein',
    flag: '🇱🇮',
    flags: ['https://flagcdn.com/li.svg', 'https://flagcdn.com/w320/li.png'],
    timezones: ['UTC+01:00'],
  },
  {
    value: 'Lithuania',
    label: 'Lithuania',
    flag: '🇱🇹',
    flags: ['https://flagcdn.com/lt.svg', 'https://flagcdn.com/w320/lt.png'],
    timezones: ['UTC+02:00'],
  },
  {
    value: 'Luxembourg',
    label: 'Luxembourg',
    flag: '🇱🇺',
    flags: ['https://flagcdn.com/lu.svg', 'https://flagcdn.com/w320/lu.png'],
    timezones: ['UTC+01:00'],
  },
  {
    value: 'Macau',
    label: 'Macau',
    flag: '🇲🇴',
    flags: ['https://flagcdn.com/mo.svg', 'https://flagcdn.com/w320/mo.png'],
    timezones: ['UTC+08:00'],
  },
  {
    value: 'Madagascar',
    label: 'Madagascar',
    flag: '🇲🇬',
    flags: ['https://flagcdn.com/mg.svg', 'https://flagcdn.com/w320/mg.png'],
    timezones: ['UTC+03:00'],
  },
  {
    value: 'Malawi',
    label: 'Malawi',
    flag: '🇲🇼',
    flags: ['https://flagcdn.com/mw.svg', 'https://flagcdn.com/w320/mw.png'],
    timezones: ['UTC+02:00'],
  },
  {
    value: 'Malaysia',
    label: 'Malaysia',
    flag: '🇲🇾',
    flags: ['https://flagcdn.com/my.svg', 'https://flagcdn.com/w320/my.png'],
    timezones: ['UTC+08:00'],
  },
  {
    value: 'Maldives',
    label: 'Maldives',
    flag: '🇲🇻',
    flags: ['https://flagcdn.com/mv.svg', 'https://flagcdn.com/w320/mv.png'],
    timezones: ['UTC+05:00'],
  },
  {
    value: 'Mali',
    label: 'Mali',
    flag: '🇲🇱',
    flags: ['https://flagcdn.com/ml.svg', 'https://flagcdn.com/w320/ml.png'],
    timezones: ['UTC'],
  },
  {
    value: 'Malta',
    label: 'Malta',
    flag: '🇲🇹',
    flags: ['https://flagcdn.com/mt.svg', 'https://flagcdn.com/w320/mt.png'],
    timezones: ['UTC+01:00'],
  },
  {
    value: 'Marshall Islands',
    label: 'Marshall Islands',
    flag: '🇲🇭',
    flags: ['https://flagcdn.com/mh.svg', 'https://flagcdn.com/w320/mh.png'],
    timezones: ['UTC+12:00'],
  },
  {
    value: 'Martinique',
    label: 'Martinique',
    flag: '🇲🇶',
    flags: ['https://flagcdn.com/mq.svg', 'https://flagcdn.com/w320/mq.png'],
    timezones: ['UTC-04:00'],
  },
  {
    value: 'Mauritania',
    label: 'Mauritania',
    flag: '🇲🇷',
    flags: ['https://flagcdn.com/mr.svg', 'https://flagcdn.com/w320/mr.png'],
    timezones: ['UTC'],
  },
  {
    value: 'Mauritius',
    label: 'Mauritius',
    flag: '🇲🇺',
    flags: ['https://flagcdn.com/mu.svg', 'https://flagcdn.com/w320/mu.png'],
    timezones: ['UTC+04:00'],
  },
  {
    value: 'Mayotte',
    label: 'Mayotte',
    flag: '🇾🇹',
    flags: ['https://flagcdn.com/yt.svg', 'https://flagcdn.com/w320/yt.png'],
    timezones: ['UTC+03:00'],
  },
  {
    value: 'Mexico',
    label: 'Mexico',
    flag: '🇲🇽',
    flags: ['https://flagcdn.com/mx.svg', 'https://flagcdn.com/w320/mx.png'],
    timezones: ['UTC-08:00', 'UTC-07:00', 'UTC-06:00'],
  },
  {
    value: 'Micronesia',
    label: 'Micronesia',
    flag: '🇫🇲',
    flags: ['https://flagcdn.com/fm.svg', 'https://flagcdn.com/w320/fm.png'],
    timezones: ['UTC+10:00', 'UTC+11:00'],
  },
  {
    value: 'Moldova',
    label: 'Moldova',
    flag: '🇲🇩',
    flags: ['https://flagcdn.com/md.svg', 'https://flagcdn.com/w320/md.png'],
    timezones: ['UTC+02:00'],
  },
  {
    value: 'Monaco',
    label: 'Monaco',
    flag: '🇲🇨',
    flags: ['https://flagcdn.com/mc.svg', 'https://flagcdn.com/w320/mc.png'],
    timezones: ['UTC+01:00'],
  },
  {
    value: 'Mongolia',
    label: 'Mongolia',
    flag: '🇲🇳',
    flags: ['https://flagcdn.com/mn.svg', 'https://flagcdn.com/w320/mn.png'],
    timezones: ['UTC+07:00', 'UTC+08:00'],
  },
  {
    value: 'Montenegro',
    label: 'Montenegro',
    flag: '🇲🇪',
    flags: ['https://flagcdn.com/me.svg', 'https://flagcdn.com/w320/me.png'],
    timezones: ['UTC+01:00'],
  },
  {
    value: 'Montserrat',
    label: 'Montserrat',
    flag: '🇲🇸',
    flags: ['https://flagcdn.com/ms.svg', 'https://flagcdn.com/w320/ms.png'],
    timezones: ['UTC-04:00'],
  },
  {
    value: 'Morocco',
    label: 'Morocco',
    flag: '🇲🇦',
    flags: ['https://flagcdn.com/ma.svg', 'https://flagcdn.com/w320/ma.png'],
    timezones: ['UTC'],
  },
  {
    value: 'Mozambique',
    label: 'Mozambique',
    flag: '🇲🇿',
    flags: ['https://flagcdn.com/mz.svg', 'https://flagcdn.com/w320/mz.png'],
    timezones: ['UTC+02:00'],
  },
  {
    value: 'Myanmar',
    label: 'Myanmar',
    flag: '🇲🇲',
    flags: ['https://flagcdn.com/mm.svg', 'https://flagcdn.com/w320/mm.png'],
    timezones: ['UTC+06:30'],
  },
  {
    value: 'Namibia',
    label: 'Namibia',
    flag: '🇳🇦',
    flags: ['https://flagcdn.com/na.svg', 'https://flagcdn.com/w320/na.png'],
    timezones: ['UTC+01:00'],
  },
  {
    value: 'Nauru',
    label: 'Nauru',
    flag: '🇳🇷',
    flags: ['https://flagcdn.com/nr.svg', 'https://flagcdn.com/w320/nr.png'],
    timezones: ['UTC+12:00'],
  },
  {
    value: 'Nepal',
    label: 'Nepal',
    flag: '🇳🇵',
    flags: ['https://flagcdn.com/np.svg', 'https://flagcdn.com/w320/np.png'],
    timezones: ['UTC+05:45'],
  },
  {
    value: 'Netherlands',
    label: 'Netherlands',
    flag: '🇳🇱',
    flags: ['https://flagcdn.com/nl.svg', 'https://flagcdn.com/w320/nl.png'],
    timezones: ['UTC-04:00', 'UTC+01:00'],
  },
  {
    value: 'New Caledonia',
    label: 'New Caledonia',
    flag: '🇳🇨',
    flags: ['https://flagcdn.com/nc.svg', 'https://flagcdn.com/w320/nc.png'],
    timezones: ['UTC+11:00'],
  },
  {
    value: 'New Zealand',
    label: 'New Zealand',
    flag: '🇳🇿',
    flags: ['https://flagcdn.com/nz.svg', 'https://flagcdn.com/w320/nz.png'],
    timezones: ['UTC-11:00', 'UTC-10:00', 'UTC+12:00', 'UTC+12:45', 'UTC+13:00'],
  },
  {
    value: 'Nicaragua',
    label: 'Nicaragua',
    flag: '🇳🇮',
    flags: ['https://flagcdn.com/ni.svg', 'https://flagcdn.com/w320/ni.png'],
    timezones: ['UTC-06:00'],
  },
  {
    value: 'Niger',
    label: 'Niger',
    flag: '🇳🇪',
    flags: ['https://flagcdn.com/ne.svg', 'https://flagcdn.com/w320/ne.png'],
    timezones: ['UTC+01:00'],
  },
  {
    value: 'Nigeria',
    label: 'Nigeria',
    flag: '🇳🇬',
    flags: ['https://flagcdn.com/ng.svg', 'https://flagcdn.com/w320/ng.png'],
    timezones: ['UTC+01:00'],
  },
  {
    value: 'Niue',
    label: 'Niue',
    flag: '🇳🇺',
    flags: ['https://flagcdn.com/nu.svg', 'https://flagcdn.com/w320/nu.png'],
    timezones: ['UTC-11:00'],
  },
  {
    value: 'Norfolk Island',
    label: 'Norfolk Island',
    flag: '🇳🇫',
    flags: ['https://flagcdn.com/nf.svg', 'https://flagcdn.com/w320/nf.png'],
    timezones: ['UTC+11:30'],
  },
  {
    value: 'North Korea',
    label: 'North Korea',
    flag: '🇰🇵',
    flags: ['https://flagcdn.com/kp.svg', 'https://flagcdn.com/w320/kp.png'],
    timezones: ['UTC+09:00'],
  },
  {
    value: 'North Macedonia',
    label: 'North Macedonia',
    flag: '🇲🇰',
    flags: ['https://flagcdn.com/mk.svg', 'https://flagcdn.com/w320/mk.png'],
    timezones: ['UTC+01:00'],
  },
  {
    value: 'Northern Mariana Islands',
    label: 'Northern Mariana Islands',
    flag: '🇲🇵',
    flags: ['https://flagcdn.com/mp.svg', 'https://flagcdn.com/w320/mp.png'],
    timezones: ['UTC+10:00'],
  },
  {
    value: 'Norway',
    label: 'Norway',
    flag: '🇳🇴',
    flags: ['https://flagcdn.com/no.svg', 'https://flagcdn.com/w320/no.png'],
    timezones: ['UTC+01:00'],
  },
  {
    value: 'Oman',
    label: 'Oman',
    flag: '🇴🇲',
    flags: ['https://flagcdn.com/om.svg', 'https://flagcdn.com/w320/om.png'],
    timezones: ['UTC+04:00'],
  },
  {
    value: 'Pakistan',
    label: 'Pakistan',
    flag: '🇵🇰',
    flags: ['https://flagcdn.com/pk.svg', 'https://flagcdn.com/w320/pk.png'],
    timezones: ['UTC+05:00'],
  },
  {
    value: 'Palau',
    label: 'Palau',
    flag: '🇵🇼',
    flags: ['https://flagcdn.com/pw.svg', 'https://flagcdn.com/w320/pw.png'],
    timezones: ['UTC+09:00'],
  },
  {
    value: 'Palestine',
    label: 'Palestine',
    flag: '🇵🇸',
    flags: ['https://flagcdn.com/ps.svg', 'https://flagcdn.com/w320/ps.png'],
    timezones: ['UTC+02:00'],
  },
  {
    value: 'Panama',
    label: 'Panama',
    flag: '🇵🇦',
    flags: ['https://flagcdn.com/pa.svg', 'https://flagcdn.com/w320/pa.png'],
    timezones: ['UTC-05:00'],
  },
  {
    value: 'Papua New Guinea',
    label: 'Papua New Guinea',
    flag: '🇵🇬',
    flags: ['https://flagcdn.com/pg.svg', 'https://flagcdn.com/w320/pg.png'],
    timezones: ['UTC+10:00'],
  },
  {
    value: 'Paraguay',
    label: 'Paraguay',
    flag: '🇵🇾',
    flags: ['https://flagcdn.com/py.svg', 'https://flagcdn.com/w320/py.png'],
    timezones: ['UTC-04:00'],
  },
  {
    value: 'Peru',
    label: 'Peru',
    flag: '🇵🇪',
    flags: ['https://flagcdn.com/pe.svg', 'https://flagcdn.com/w320/pe.png'],
    timezones: ['UTC-05:00'],
  },
  {
    value: 'Philippines',
    label: 'Philippines',
    flag: '🇵🇭',
    flags: ['https://flagcdn.com/ph.svg', 'https://flagcdn.com/w320/ph.png'],
    timezones: ['UTC+08:00'],
  },
  {
    value: 'Pitcairn Islands',
    label: 'Pitcairn Islands',
    flag: '🇵🇳',
    flags: ['https://flagcdn.com/pn.svg', 'https://flagcdn.com/w320/pn.png'],
    timezones: ['UTC-08:00'],
  },
  {
    value: 'Poland',
    label: 'Poland',
    flag: '🇵🇱',
    flags: ['https://flagcdn.com/pl.svg', 'https://flagcdn.com/w320/pl.png'],
    timezones: ['UTC+01:00'],
  },
  {
    value: 'Portugal',
    label: 'Portugal',
    flag: '🇵🇹',
    flags: ['https://flagcdn.com/pt.svg', 'https://flagcdn.com/w320/pt.png'],
    timezones: ['UTC-01:00', 'UTC'],
  },
  {
    value: 'Puerto Rico',
    label: 'Puerto Rico',
    flag: '🇵🇷',
    flags: ['https://flagcdn.com/pr.svg', 'https://flagcdn.com/w320/pr.png'],
    timezones: ['UTC-04:00'],
  },
  {
    value: 'Qatar',
    label: 'Qatar',
    flag: '🇶🇦',
    flags: ['https://flagcdn.com/qa.svg', 'https://flagcdn.com/w320/qa.png'],
    timezones: ['UTC+03:00'],
  },
  {
    value: 'Republic of the Congo',
    label: 'Republic of the Congo',
    flag: '🇨🇬',
    flags: ['https://flagcdn.com/cg.svg', 'https://flagcdn.com/w320/cg.png'],
    timezones: ['UTC+01:00'],
  },
  {
    value: 'Romania',
    label: 'Romania',
    flag: '🇷🇴',
    flags: ['https://flagcdn.com/ro.svg', 'https://flagcdn.com/w320/ro.png'],
    timezones: ['UTC+02:00'],
  },
  {
    value: 'Russia',
    label: 'Russia',
    flag: '🇷🇺',
    flags: ['https://flagcdn.com/ru.svg', 'https://flagcdn.com/w320/ru.png'],
    timezones: [
      'UTC+03:00',
      'UTC+04:00',
      'UTC+06:00',
      'UTC+07:00',
      'UTC+08:00',
      'UTC+09:00',
      'UTC+10:00',
      'UTC+11:00',
      'UTC+12:00',
    ],
  },
  {
    value: 'Rwanda',
    label: 'Rwanda',
    flag: '🇷🇼',
    flags: ['https://flagcdn.com/rw.svg', 'https://flagcdn.com/w320/rw.png'],
    timezones: ['UTC+02:00'],
  },
  {
    value: 'Réunion',
    label: 'Réunion',
    flag: '🇷🇪',
    flags: ['https://flagcdn.com/re.svg', 'https://flagcdn.com/w320/re.png'],
    timezones: ['UTC+04:00'],
  },
  {
    value: 'Saint Barthélemy',
    label: 'Saint Barthélemy',
    flag: '🇧🇱',
    flags: ['https://flagcdn.com/bl.svg', 'https://flagcdn.com/w320/bl.png'],
    timezones: ['UTC-04:00'],
  },
  {
    value: 'Saint Helena, Ascension and Tristan da Cunha',
    label: 'Saint Helena, Ascension and Tristan da Cunha',
    flag: '🇸🇭',
    flags: ['https://flagcdn.com/sh.svg', 'https://flagcdn.com/w320/sh.png'],
    timezones: ['UTC+00:00'],
  },
  {
    value: 'Saint Kitts and Nevis',
    label: 'Saint Kitts and Nevis',
    flag: '🇰🇳',
    flags: ['https://flagcdn.com/kn.svg', 'https://flagcdn.com/w320/kn.png'],
    timezones: ['UTC-04:00'],
  },
  {
    value: 'Saint Lucia',
    label: 'Saint Lucia',
    flag: '🇱🇨',
    flags: ['https://flagcdn.com/lc.svg', 'https://flagcdn.com/w320/lc.png'],
    timezones: ['UTC-04:00'],
  },
  {
    value: 'Saint Martin',
    label: 'Saint Martin',
    flag: '🇲🇫',
    flags: ['https://flagcdn.com/mf.svg', 'https://flagcdn.com/w320/mf.png'],
    timezones: ['UTC-04:00'],
  },
  {
    value: 'Saint Pierre and Miquelon',
    label: 'Saint Pierre and Miquelon',
    flag: '🇵🇲',
    flags: ['https://flagcdn.com/pm.svg', 'https://flagcdn.com/w320/pm.png'],
    timezones: ['UTC-03:00'],
  },
  {
    value: 'Saint Vincent and the Grenadines',
    label: 'Saint Vincent and the Grenadines',
    flag: '🇻🇨',
    flags: ['https://flagcdn.com/vc.svg', 'https://flagcdn.com/w320/vc.png'],
    timezones: ['UTC-04:00'],
  },
  {
    value: 'Samoa',
    label: 'Samoa',
    flag: '🇼🇸',
    flags: ['https://flagcdn.com/ws.svg', 'https://flagcdn.com/w320/ws.png'],
    timezones: ['UTC+13:00'],
  },
  {
    value: 'San Marino',
    label: 'San Marino',
    flag: '🇸🇲',
    flags: ['https://flagcdn.com/sm.svg', 'https://flagcdn.com/w320/sm.png'],
    timezones: ['UTC+01:00'],
  },
  {
    value: 'Saudi Arabia',
    label: 'Saudi Arabia',
    flag: '🇸🇦',
    flags: ['https://flagcdn.com/sa.svg', 'https://flagcdn.com/w320/sa.png'],
    timezones: ['UTC+03:00'],
  },
  {
    value: 'Senegal',
    label: 'Senegal',
    flag: '🇸🇳',
    flags: ['https://flagcdn.com/sn.svg', 'https://flagcdn.com/w320/sn.png'],
    timezones: ['UTC'],
  },
  {
    value: 'Serbia',
    label: 'Serbia',
    flag: '🇷🇸',
    flags: ['https://flagcdn.com/rs.svg', 'https://flagcdn.com/w320/rs.png'],
    timezones: ['UTC+01:00'],
  },
  {
    value: 'Seychelles',
    label: 'Seychelles',
    flag: '🇸🇨',
    flags: ['https://flagcdn.com/sc.svg', 'https://flagcdn.com/w320/sc.png'],
    timezones: ['UTC+04:00'],
  },
  {
    value: 'Sierra Leone',
    label: 'Sierra Leone',
    flag: '🇸🇱',
    flags: ['https://flagcdn.com/sl.svg', 'https://flagcdn.com/w320/sl.png'],
    timezones: ['UTC'],
  },
  {
    value: 'Singapore',
    label: 'Singapore',
    flag: '🇸🇬',
    flags: ['https://flagcdn.com/sg.svg', 'https://flagcdn.com/w320/sg.png'],
    timezones: ['UTC+08:00'],
  },
  {
    value: 'Sint Maarten',
    label: 'Sint Maarten',
    flag: '🇸🇽',
    flags: ['https://flagcdn.com/sx.svg', 'https://flagcdn.com/w320/sx.png'],
    timezones: ['UTC-04:00'],
  },
  {
    value: 'Slovakia',
    label: 'Slovakia',
    flag: '🇸🇰',
    flags: ['https://flagcdn.com/sk.svg', 'https://flagcdn.com/w320/sk.png'],
    timezones: ['UTC+01:00'],
  },
  {
    value: 'Slovenia',
    label: 'Slovenia',
    flag: '🇸🇮',
    flags: ['https://flagcdn.com/si.svg', 'https://flagcdn.com/w320/si.png'],
    timezones: ['UTC+01:00'],
  },
  {
    value: 'Solomon Islands',
    label: 'Solomon Islands',
    flag: '🇸🇧',
    flags: ['https://flagcdn.com/sb.svg', 'https://flagcdn.com/w320/sb.png'],
    timezones: ['UTC+11:00'],
  },
  {
    value: 'Somalia',
    label: 'Somalia',
    flag: '🇸🇴',
    flags: ['https://flagcdn.com/so.svg', 'https://flagcdn.com/w320/so.png'],
    timezones: ['UTC+03:00'],
  },
  {
    value: 'South Africa',
    label: 'South Africa',
    flag: '🇿🇦',
    flags: ['https://flagcdn.com/za.svg', 'https://flagcdn.com/w320/za.png'],
    timezones: ['UTC+02:00'],
  },
  {
    value: 'South Georgia',
    label: 'South Georgia',
    flag: '🇬🇸',
    flags: ['https://flagcdn.com/gs.svg', 'https://flagcdn.com/w320/gs.png'],
    timezones: ['UTC-02:00'],
  },
  {
    value: 'South Korea',
    label: 'South Korea',
    flag: '🇰🇷',
    flags: ['https://flagcdn.com/kr.svg', 'https://flagcdn.com/w320/kr.png'],
    timezones: ['UTC+09:00'],
  },
  {
    value: 'South Sudan',
    label: 'South Sudan',
    flag: '🇸🇸',
    flags: ['https://flagcdn.com/ss.svg', 'https://flagcdn.com/w320/ss.png'],
    timezones: ['UTC+03:00'],
  },
  {
    value: 'Spain',
    label: 'Spain',
    flag: '🇪🇸',
    flags: ['https://flagcdn.com/es.svg', 'https://flagcdn.com/w320/es.png'],
    timezones: ['UTC', 'UTC+01:00'],
  },
  {
    value: 'Sri Lanka',
    label: 'Sri Lanka',
    flag: '🇱🇰',
    flags: ['https://flagcdn.com/lk.svg', 'https://flagcdn.com/w320/lk.png'],
    timezones: ['UTC+05:30'],
  },
  {
    value: 'Sudan',
    label: 'Sudan',
    flag: '🇸🇩',
    flags: ['https://flagcdn.com/sd.svg', 'https://flagcdn.com/w320/sd.png'],
    timezones: ['UTC+03:00'],
  },
  {
    value: 'Suriname',
    label: 'Suriname',
    flag: '🇸🇷',
    flags: ['https://flagcdn.com/sr.svg', 'https://flagcdn.com/w320/sr.png'],
    timezones: ['UTC-03:00'],
  },
  {
    value: 'Svalbard and Jan Mayen',
    label: 'Svalbard and Jan Mayen',
    flag: '🇸🇯',
    flags: ['https://flagcdn.com/sj.svg', 'https://flagcdn.com/w320/sj.png'],
    timezones: ['UTC+01:00'],
  },
  {
    value: 'Sweden',
    label: 'Sweden',
    flag: '🇸🇪',
    flags: ['https://flagcdn.com/se.svg', 'https://flagcdn.com/w320/se.png'],
    timezones: ['UTC+01:00'],
  },
  {
    value: 'Switzerland',
    label: 'Switzerland',
    flag: '🇨🇭',
    flags: ['https://flagcdn.com/ch.svg', 'https://flagcdn.com/w320/ch.png'],
    timezones: ['UTC+01:00'],
  },
  {
    value: 'Syria',
    label: 'Syria',
    flag: '🇸🇾',
    flags: ['https://flagcdn.com/sy.svg', 'https://flagcdn.com/w320/sy.png'],
    timezones: ['UTC+02:00'],
  },
  {
    value: 'São Tomé and Príncipe',
    label: 'São Tomé and Príncipe',
    flag: '🇸🇹',
    flags: ['https://flagcdn.com/st.svg', 'https://flagcdn.com/w320/st.png'],
    timezones: ['UTC'],
  },
  {
    value: 'Taiwan',
    label: 'Taiwan',
    flag: '🇹🇼',
    flags: ['https://flagcdn.com/tw.svg', 'https://flagcdn.com/w320/tw.png'],
    timezones: ['UTC+08:00'],
  },
  {
    value: 'Tajikistan',
    label: 'Tajikistan',
    flag: '🇹🇯',
    flags: ['https://flagcdn.com/tj.svg', 'https://flagcdn.com/w320/tj.png'],
    timezones: ['UTC+05:00'],
  },
  {
    value: 'Tanzania',
    label: 'Tanzania',
    flag: '🇹🇿',
    flags: ['https://flagcdn.com/tz.svg', 'https://flagcdn.com/w320/tz.png'],
    timezones: ['UTC+03:00'],
  },
  {
    value: 'Thailand',
    label: 'Thailand',
    flag: '🇹🇭',
    flags: ['https://flagcdn.com/th.svg', 'https://flagcdn.com/w320/th.png'],
    timezones: ['UTC+07:00'],
  },
  {
    value: 'Timor-Leste',
    label: 'Timor-Leste',
    flag: '🇹🇱',
    flags: ['https://flagcdn.com/tl.svg', 'https://flagcdn.com/w320/tl.png'],
    timezones: ['UTC+09:00'],
  },
  {
    value: 'Togo',
    label: 'Togo',
    flag: '🇹🇬',
    flags: ['https://flagcdn.com/tg.svg', 'https://flagcdn.com/w320/tg.png'],
    timezones: ['UTC'],
  },
  {
    value: 'Tokelau',
    label: 'Tokelau',
    flag: '🇹🇰',
    flags: ['https://flagcdn.com/tk.svg', 'https://flagcdn.com/w320/tk.png'],
    timezones: ['UTC+13:00'],
  },
  {
    value: 'Tonga',
    label: 'Tonga',
    flag: '🇹🇴',
    flags: ['https://flagcdn.com/to.svg', 'https://flagcdn.com/w320/to.png'],
    timezones: ['UTC+13:00'],
  },
  {
    value: 'Trinidad and Tobago',
    label: 'Trinidad and Tobago',
    flag: '🇹🇹',
    flags: ['https://flagcdn.com/tt.svg', 'https://flagcdn.com/w320/tt.png'],
    timezones: ['UTC-04:00'],
  },
  {
    value: 'Tunisia',
    label: 'Tunisia',
    flag: '🇹🇳',
    flags: ['https://flagcdn.com/tn.svg', 'https://flagcdn.com/w320/tn.png'],
    timezones: ['UTC+01:00'],
  },
  {
    value: 'Turkey',
    label: 'Turkey',
    flag: '🇹🇷',
    flags: ['https://flagcdn.com/tr.svg', 'https://flagcdn.com/w320/tr.png'],
    timezones: ['UTC+03:00'],
  },
  {
    value: 'Turkmenistan',
    label: 'Turkmenistan',
    flag: '🇹🇲',
    flags: ['https://flagcdn.com/tm.svg', 'https://flagcdn.com/w320/tm.png'],
    timezones: ['UTC+05:00'],
  },
  {
    value: 'Turks and Caicos Islands',
    label: 'Turks and Caicos Islands',
    flag: '🇹🇨',
    flags: ['https://flagcdn.com/tc.svg', 'https://flagcdn.com/w320/tc.png'],
    timezones: ['UTC-04:00'],
  },
  {
    value: 'Tuvalu',
    label: 'Tuvalu',
    flag: '🇹🇻',
    flags: ['https://flagcdn.com/tv.svg', 'https://flagcdn.com/w320/tv.png'],
    timezones: ['UTC+12:00'],
  },
  {
    value: 'Uganda',
    label: 'Uganda',
    flag: '🇺🇬',
    flags: ['https://flagcdn.com/ug.svg', 'https://flagcdn.com/w320/ug.png'],
    timezones: ['UTC+03:00'],
  },
  {
    value: 'Ukraine',
    label: 'Ukraine',
    flag: '🇺🇦',
    flags: ['https://flagcdn.com/ua.svg', 'https://flagcdn.com/w320/ua.png'],
    timezones: ['UTC+02:00'],
  },
  {
    value: 'United Arab Emirates',
    label: 'United Arab Emirates',
    flag: '🇦🇪',
    flags: ['https://flagcdn.com/ae.svg', 'https://flagcdn.com/w320/ae.png'],
    timezones: ['UTC+04:00'],
  },
  {
    value: 'United Kingdom',
    label: 'United Kingdom',
    flag: '🇬🇧',
    flags: ['https://flagcdn.com/gb.svg', 'https://flagcdn.com/w320/gb.png'],
    timezones: [
      'UTC-08:00',
      'UTC-05:00',
      'UTC-04:00',
      'UTC-03:00',
      'UTC-02:00',
      'UTC',
      'UTC+01:00',
      'UTC+02:00',
      'UTC+06:00',
    ],
  },
  {
    value: 'United States',
    label: 'United States',
    flag: '🇺🇸',
    flags: ['https://flagcdn.com/us.svg', 'https://flagcdn.com/w320/us.png'],
    timezones: [
      'UTC-12:00',
      'UTC-11:00',
      'UTC-10:00',
      'UTC-09:00',
      'UTC-08:00',
      'UTC-07:00',
      'UTC-06:00',
      'UTC-05:00',
      'UTC-04:00',
      'UTC+10:00',
      'UTC+12:00',
    ],
  },
  {
    value: 'United States Minor Outlying Islands',
    label: 'United States Minor Outlying Islands',
    flag: '🇺🇲',
    flags: ['https://flagcdn.com/um.svg', 'https://flagcdn.com/w320/um.png'],
    timezones: ['UTC-11:00', 'UTC-10:00', 'UTC+12:00'],
  },
  {
    value: 'United States Virgin Islands',
    label: 'United States Virgin Islands',
    flag: '🇻🇮',
    flags: ['https://flagcdn.com/vi.svg', 'https://flagcdn.com/w320/vi.png'],
    timezones: ['UTC-04:00'],
  },
  {
    value: 'Uruguay',
    label: 'Uruguay',
    flag: '🇺🇾',
    flags: ['https://flagcdn.com/uy.svg', 'https://flagcdn.com/w320/uy.png'],
    timezones: ['UTC-03:00'],
  },
  {
    value: 'Uzbekistan',
    label: 'Uzbekistan',
    flag: '🇺🇿',
    flags: ['https://flagcdn.com/uz.svg', 'https://flagcdn.com/w320/uz.png'],
    timezones: ['UTC+05:00'],
  },
  {
    value: 'Vanuatu',
    label: 'Vanuatu',
    flag: '🇻🇺',
    flags: ['https://flagcdn.com/vu.svg', 'https://flagcdn.com/w320/vu.png'],
    timezones: ['UTC+11:00'],
  },
  {
    value: 'Vatican City',
    label: 'Vatican City',
    flag: '🇻🇦',
    flags: ['https://flagcdn.com/va.svg', 'https://flagcdn.com/w320/va.png'],
    timezones: ['UTC+01:00'],
  },
  {
    value: 'Venezuela',
    label: 'Venezuela',
    flag: '🇻🇪',
    flags: ['https://flagcdn.com/ve.svg', 'https://flagcdn.com/w320/ve.png'],
    timezones: ['UTC-04:00'],
  },
  {
    value: 'Vietnam',
    label: 'Vietnam',
    flag: '🇻🇳',
    flags: ['https://flagcdn.com/vn.svg', 'https://flagcdn.com/w320/vn.png'],
    timezones: ['UTC+07:00'],
  },
  {
    value: 'Wallis and Futuna',
    label: 'Wallis and Futuna',
    flag: '🇼🇫',
    flags: ['https://flagcdn.com/wf.svg', 'https://flagcdn.com/w320/wf.png'],
    timezones: ['UTC+12:00'],
  },
  {
    value: 'Western Sahara',
    label: 'Western Sahara',
    flag: '🇪🇭',
    flags: ['https://flagcdn.com/eh.svg', 'https://flagcdn.com/w320/eh.png'],
    timezones: ['UTC+00:00'],
  },
  {
    value: 'Yemen',
    label: 'Yemen',
    flag: '🇾🇪',
    flags: ['https://flagcdn.com/ye.svg', 'https://flagcdn.com/w320/ye.png'],
    timezones: ['UTC+03:00'],
  },
  {
    value: 'Zambia',
    label: 'Zambia',
    flag: '🇿🇲',
    flags: ['https://flagcdn.com/zm.svg', 'https://flagcdn.com/w320/zm.png'],
    timezones: ['UTC+02:00'],
  },
  {
    value: 'Zimbabwe',
    label: 'Zimbabwe',
    flag: '🇿🇼',
    flags: ['https://flagcdn.com/zw.svg', 'https://flagcdn.com/w320/zw.png'],
    timezones: ['UTC+02:00'],
  },
];

export const CountryOptions = (): Array<{ value: string; label: string }> => {
  return COUNTRIES.map((item) => {
    return {
      value: item.value,
      label: `${item.flag} ${item.label}`,
      flag: item.flag,
      flags: item.flags,
      timezones: item.timezones,
    };
  });
};

export const findCountryByValue = (value: string) => CountryOptions().find((o) => o.value == value);
export const findCountryLabelByValue = (value: string) => findCountryByValue(value)?.label;
