import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { AnimatedButton } from '../../../../_theme/helpers/components/AnimatedButton';

export interface BrowserWebcamProps {
  width?: number;
  height?: number;
  maxDurationSeconds?: number;
  onCancel: () => void;
  onSave: (e: File, transcript: string) => Promise<boolean>;
  setTimerSeconds: (e: number) => void;
  setTimerStarted: (e: boolean) => void;
  isPractice?: boolean;
  useTimer?: boolean;
}

export const BrowserWebcam: FC<BrowserWebcamProps> = (props: BrowserWebcamProps) => {
  const { setTimerSeconds, setTimerStarted, isPractice = false, useTimer = true } = props;
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [ready, setReady] = useState<boolean>(false);
  const [capturing, setCapturing] = useState<boolean>(false);
  const [preparing, setPreparing] = useState<boolean>(false);
  const [completedRecording, setCompletedRecording] = useState<boolean>(false);
  const [recordedChunks, setRecordedChunks] = useState<Array<any>>([]);

  const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } =
    useSpeechRecognition();

  // Start Recoding on load
  useEffect(() => {
    if (!isPractice) {
      handleStartRecording();
    } else {
      // to similate the recording
      setTimerStarted(true);
      setCapturing(true);
      setPreparing(false);
    }
  }, []);

  useEffect(() => {
    if (completedRecording && recordedChunks.length > 0) {
      handleSave(); // Trigger save only when chunks are ready
    }
  }, [recordedChunks, completedRecording]);

  const startOver = () => {
    setTimerSeconds(props.maxDurationSeconds ?? 300);
    setTimerStarted(false);
    setPreparing(false);
    setCapturing(false);
    setCompletedRecording(false);
    setRecordedChunks([]);
    handleStartRecording();
    resetTranscript();
  };

  const handleDataAvailable = useCallback(({ data }: any) => {
    if (data.size > 0) {
      setRecordedChunks((prev) => [...prev, data]);
    } else {
      console.warn('Data chunk size is 0');
    }
  }, []);

  const handleStartRecording = useCallback(async () => {
    setPreparing(true);
    await new Promise((resolve) => setTimeout(resolve, 3000));
    setPreparing(false);
    setCapturing(true);
    setTimerStarted(true);

    if (!isPractice) {
      // @ts-ignore
      const stream = webcamRef.current?.video?.srcObject;

      if (stream) {
        // @ts-ignore
        mediaRecorderRef.current = new MediaRecorder(stream, {
          mimeType: 'video/webm;codecs=vp8,opus',
        });

        SpeechRecognition.startListening({ continuous: true });

        // @ts-ignore
        mediaRecorderRef.current.addEventListener('dataavailable', handleDataAvailable);
        // @ts-ignore
        mediaRecorderRef.current.start();
      } else {
        console.error('Stream is not available.');
      }
    }
  }, [handleDataAvailable]);

  const handleStopRecording = useCallback(() => {
    setCompletedRecording(true);
    setPreparing(true);
    if (!isPractice && mediaRecorderRef.current) {
      // @ts-ignore
      mediaRecorderRef.current.stop();
      SpeechRecognition.stopListening();
    }
    setCapturing(false);
    setTimerStarted(false);
  }, []);

  const getFile = useCallback(() => {
    const blob = new Blob(recordedChunks, {
      type: 'video/webm;codecs=vp8,opus',
    });
    return new File([blob], 'filename', {
      type: 'video/webm;codecs=vp8,opus',
      lastModified: new Date().getTime(),
    });
  }, [recordedChunks]);

  const handleSave = async () => {
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setCompletedRecording(false);
    setPreparing(true);
    const response = await props.onSave(getFile(), transcript || '');
    if (response) {
      startOver();
    }
  };

  const videoConstraints = {
    width: props.width ?? 720,
    height: props.height ?? 420,
    facingMode: 'user',
  };

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  return (
    <div className="container w-full">
      <div className={'relative'}>
        <Webcam
          onUserMedia={() => setReady(true)}
          muted={true}
          className={'!rounded-[10px] mx-auto'}
          audio={true}
          mirrored={true}
          ref={webcamRef}
          videoConstraints={videoConstraints}
          height={420}
          width={720}
        />
        <div className={'position-absolute start-50 translate-middle-x'} style={{ top: '76%' }}>
          {!ready ||
            (preparing ? (
              <AnimatedButton
                processing={true}
                disabled={false}
                size={'md'}
                className={'btn btn-xl btn-dark'}
              />
            ) : (
              useTimer && (
                <div className="w-[100px] bg-black px-4 py-2 rounded flex items-center opacity-75">
                  <span className="animate-ping absolute h-[6px] w-[6px] rounded-full bg-alert z-max" />
                  <span className="ml-4 font-bold text-white">Recording</span>
                </div>
              )
            ))}
          <>
            {!preparing && capturing && (
              <button
                className={
                  'invisible btn btn-xl btn-danger fw-bolder main-button main-gradient !text-white !rounded-full'
                }
                onClick={handleStopRecording}
                id="stop-recording-btn"
              >
                Stop Recording
              </button>
            )}
          </>
        </div>
        {listening && transcript ? (
          <div className="block mt-2 px-6 py-4 text-body15 !border-[1px] !border-priLight2 rounded-[20px]">
            <span className="text-h3 font-bold block mb-2">Transcript:</span>
            {transcript}
          </div>
        ) : null}
      </div>
    </div>
  );
};
