import React, { FC, useCallback, useRef, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import toast from 'react-hot-toast';
import axios from 'axios';
import { Config } from '../../../../../config';

export interface EditAvatarProps {
  user_id: string;
  processing: boolean;
  existing_avatar_url: string;
  refetch: () => void;
  is_default: boolean;
}

interface FileObject extends Blob {
  lastModified: number;
  name: string;
  size: number;
  type: string;
}

export const EditAvatar: FC<EditAvatarProps> = (props: EditAvatarProps) => {
  const [processing, setProcessing] = useState<boolean>(props.processing);
  const inputRef = useRef<HTMLFormElement>();

  const handleClick = (): void => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const onInputChange = useCallback(async (e: any) => {
    const leImage: FileObject = e.target.files[0];

    if (!leImage) {
      resetImage(e);
      toast.error('File is invalid');
      return;
    }

    if (fileIsTooBig(leImage)) {
      resetImage(e);
      toast.error('File must be less than 2mb');
      return;
    }

    let reader = new FileReader();
    reader.readAsDataURL(leImage);

    if (fileIsTooBig(leImage)) {
      toast.error('File must be less than 2mb.');
      return;
    }

    setProcessing(true);

    try {
      // Step 1: Get presigned URL
      const response = await axios.post(`${Config.api_url}/generate-presigned-url`, {
        fileName: leImage.name,
        fileType: leImage.type,
      });

      const presignedUrl = response?.data?.url;

      // Step 2: Upload file to S3
      await fetch(presignedUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': leImage.type,
        },
        body: leImage,
      });

      const newImageLocation = `https://${Config.services.aws.bucket}.s3.amazonaws.com/${leImage.name}`;

      await updateProfileAvatar(newImageLocation);
      setProcessing(false);
      // props.onChange(newImageLocation);

      resetInputRef();
    } catch (err) {
      setProcessing(false);
      toast.error('Error uploading file');
    }
  }, []);

  const resetImage = (e: any) => {
    e.target.value = null;
    resetInputRef();
  };

  const resetInputRef = (): void => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const getProfileImage = () => {
    return props.existing_avatar_url;
  };

  const fileIsTooBig = (file: any): boolean => {
    return file.size / 1024 / 1024 > 2;
  };

  const handleReset = async () => {
    await axios.delete(`${Config.api_url}/candidate/profile/avatar`);
    toast.success('Avatar Updated!');
  };

  const updateProfileAvatar = async (avatarLoc: string | null): Promise<any> => {
    await axios.patch(`${Config.api_url}/candidate/profile/avatar`, { location: avatarLoc });
    toast.success('Avatar Updated!');
    props.refetch();
  };

  return (
    <>
      <div className="col-lg-8 flex">
        <div className="symbol symbol-125px symbol-circle  me-10">
          <img className="mw-125px" alt="Profile" src={getProfileImage()} />
        </div>
        <div className="flex justify-center  flex-col">
          <div>
            <input
              ref={inputRef as any}
              type="file"
              name="avatar"
              accept="image/apng, image/avif, image/gif, image/jpeg, image/png"
              style={{ display: 'none' }}
              onChange={onInputChange}
            />
            {/* {imageSrc && (
              <button
                className="btn btn-sm btn-primary me-3 main-button main-gradient"
                type="button"
                disabled={processing}
                onClick={handleFileUpload}
              >
                Save Image
              </button>
            )} */}

            <button
              className="btn btn-sm btn-light me-3 main-button text-alert"
              type="button"
              disabled={processing}
              onClick={handleClick}
            >
              Choose Image
            </button>
            <OverlayTrigger
              trigger={['hover', 'focus']}
              placement="top"
              delay={{ show: 100, hide: 200 }}
              overlay={<Tooltip id="button-tooltip">Remove Image</Tooltip>}
            >
              <button
                className="btn btn-sm btn-light ml-2 main-button"
                type="button"
                disabled={processing}
                onClick={handleReset}
              >
                <i className="fa-solid fa-trash pe-0" />
              </button>
            </OverlayTrigger>
          </div>
        </div>
      </div>
    </>
  );
};
