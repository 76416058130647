import React, { FC, useEffect, useState } from 'react';
import { Config } from '../../../../../config';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import * as VetAI from '../../AiInterviews/VetAI';
import { VetAIInterviewType } from '../../AiInterviews/constants';

export interface VetAIProps {
  userId: string;
  isProfileComplete: { isComplete: boolean; missingFields: string[] };
}

type ModalView =
  | 'profileCompletness'
  | 'areYouReady'
  | 'interview'
  | 'practice'
  | 'completed'
  | 'requestAnother'
  | 'feedback';

export const VetAIInterview: FC<VetAIProps> = (props: VetAIProps) => {
  const [started, setStarted] = useState<boolean>(false);
  const [view, setView] = useState<ModalView>('profileCompletness');
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    const handleBeforeUnload = (e: any) => {
      if (started) {
        const confirmationMessage =
          'You will need to request another interview if you leave now. Are you sure you want to leave?';
        e.preventDefault();
        e.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [started]);

  const fetchInterviews = async () => {
    const response = await axios.get(
      `${Config.api_url}/candidate/ai-interview/list?candidate_id=${props?.userId}&statuses[]=pending&statuses[]=completed&statuses[]=scoring&include_questions=0`
    );
    return response.data.data;
  };

  const { isLoading, data } = useQuery<VetAIInterviewType[]>(
    ['candidate.ai-interview', props.userId],
    fetchInterviews
  );

  const handleClose = () => {
    setView('profileCompletness');
    setShow(false);
  };

  if (isLoading) {
    return 'Loading...';
  }

  return (
    <>
      <Modal
        centered
        backdrop="static"
        keyboard={false}
        className={'fade !p-0'}
        animation={true}
        show={show && view !== 'practice' && view !== 'interview'}
      >
        <Modal.Body className="!p-0">
          <span className="top-border block w-full h-[10px] rounded-t-[6px]" />

          {view === 'profileCompletness' && (
            <div className="modal-body">
              {!props.isProfileComplete.isComplete ? (
                <VetAI.MissingProfile
                  handleClose={handleClose}
                  missingFields={props.isProfileComplete.missingFields}
                />
              ) : (
                <VetAI.StartInterview
                  handleClose={handleClose}
                  handleStart={() => {
                    setView('areYouReady');
                  }}
                  handleStartPractice={() => {
                    setView('practice');
                  }}
                />
              )}
            </div>
          )}

          {view === 'areYouReady' && (
            <VetAI.AreYouReady
              handleClose={handleClose}
              handleStart={() => {
                setView('interview');
                setStarted(true);
              }}
            />
          )}

          {view === 'requestAnother' && (
            <VetAI.RequestAnotherInterview handleClose={() => setShow(false)} />
          )}

          {view === 'completed' && (
            <VetAI.InterviewCompleted handleStartFeedback={() => setView('feedback')} />
          )}

          {view === 'feedback' && <VetAI.Feedback handleClose={() => setShow(false)} />}
        </Modal.Body>
      </Modal>

      {(view === 'practice' || view === 'interview') && (
        // the z index needs to the match the overlay z index of the joyride comp
        <div className="fixed top-0 left-0 w-screen h-screen bg-white z-[199]">
          <VetAI.Interview
            userId={props.userId}
            isPractice={view === 'practice'}
            handleCancel={handleClose}
            handleFinish={() => {
              setView(view === 'practice' ? 'areYouReady' : 'completed');
            }}
          />
        </div>
      )}

      {/* Only allow to do an interview if it has not been recorded */}
      {data?.length === 0 ? (
        <div className="mt-8 relative cursor-pointer" onClick={() => setShow(true)}>
          <img
            src="/media/pages/interview_banner.png"
            alt="banner"
            className="!w-[590px] !h-[168px] object-cover"
          />
          <div
            className={
              'absolute rounded-[16px] top-0 !w-full !h-[168px] bg-gradient-to-r from-[#732FA9A3] to-[#BF09FF]'
            }
          >
            <div
              className={
                'flex flex-col justify-center items-center h-[168px] max-w-[772px] text-center mx-auto'
              }
            >
              <img
                alt="interview icon"
                src="/media/icons/interview_icon.png"
                className="!w-[56px] !h-[56px] mb-2"
              />
              <h2 className={'text-white text-body15 font-bold mb-2'}>
                Record Your AI Live Introduction Interview
              </h2>
              <h5 className="text-white text-body12 font-medium">
                To get matched with the best opportunities, please record your introduction
                interview. This helps us understand your background and skills, ensuring we find the
                perfect fit for you.
              </h5>
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-16 text-center">
          <h3 className="text-h3 font-bold">
            You have already completed your <span className="text-pri">VetAI</span> interview!
          </h3>

          <Link
            to={`/candidate/interviews`}
            className="btn btn-primary btn-sm main-gradient main-button block my-4"
          >
            Go to Interviews
          </Link>

          <p>
            If you want to record your VetAI interview again, you can{' '}
            <span
              className="text-pri cursor-pointer"
              onClick={() => {
                setView('requestAnother');
                setShow(true);
              }}
            >
              request another interview.
            </span>
          </p>
        </div>
      )}
    </>
  );
};
