import clsx from 'clsx';
import { ToolbarType, useLayout } from '../../core';
import { Toolbar } from './Toolbar';
import { PageTitleWrapper } from './page-title';
import { Link } from 'react-router-dom';
import { Avatar } from '../../../../app/util/avatar';
import { useAuth } from '../../../../app/modules/auth';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { fetchHasUnreadNotifications } from '../../../../app/modules/notifications/FetchNotifications';
import { useEffect, useState } from 'react';
import { KTSVG } from '../../../helpers';

const ToolbarWrapper = () => {
  const auth = useAuth();
  const [unread, setUnread] = useState(false);
  const { config, classes } = useLayout();

  useEffect(() => {
    async function checkUnreadNotifications() {
      const response = await fetchHasUnreadNotifications();
      setUnread(response);
    }

    checkUnreadNotifications();
  }, [unread]);

  if (!config.app?.toolbar?.display) {
    return null;
  }

  const isPageTitleVisible = showPageTitle(
    config.app?.toolbar?.layout,
    config.app?.pageTitle?.display
  );

  return (
    <div
      id="distro_app_toolbar"
      className={clsx(
        'app-toolbar',
        classes.toolbar.join(' '),
        config?.app?.toolbar?.class,
        auth.currentUser?.user_type === 'candidate'
          ? '!min-h-[180px] !h-[180px]'
          : '!min-h-[120px] !h-[120px]'
      )}
    >
      <div className="d-flex d-lg-none ms-4 me-1" title="Show sidebar menu">
        <div
          className="btn btn-icon btn-active-color-primary w-35px h-35px"
          id="distro_app_sidebar_mobile_toggle"
        >
          <KTSVG path="/media/icons/duotune/abstract/abs015.svg" className=" svg-icon-1" />
        </div>
      </div>

      <div
        id="distro_app_toolbar_container"
        className={clsx(
          'app-container',
          classes.toolbarContainer.join(' '),
          config.app?.toolbar?.containerClass,
          config.app?.toolbar?.minimize?.enabled ? 'app-toolbar-minimize' : '',
          auth.currentUser?.user_type === 'candidate' ? '' : 'p-6',
          {
            'container-fluid': config.app?.toolbar?.container === 'fluid',
            'container-xxl': config.app?.toolbar?.container === 'fixed',
          }
        )}
      >
        <Toolbar />
      </div>
    </div>
  );
};

const showPageTitle = (appToolbarLayout?: ToolbarType, appPageTitleDisplay?: boolean): boolean => {
  const viewsWithPageTitles = ['classic', 'reports', 'saas'];
  if (!appToolbarLayout || !appPageTitleDisplay) {
    return false;
  }

  return appPageTitleDisplay && viewsWithPageTitles.some((t) => t === appToolbarLayout);
};

export { ToolbarWrapper };
