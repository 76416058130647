import { useMemo } from 'react';

const MiniProgressBar = ({ progress }: { progress: number }) => {
  return (
    <div className="h-[5px] w-full rounded-full bg-[#ffffff40]">
      <div style={{ width: `${progress}%` }} className={`block h-[5px] bg-white rounded-full`} />
    </div>
  );
};

interface T {
  step1Progress: number;
  step2Progress: number;
  step3Progress: number;
  step4Progress: number;
}

const CandidateBanner = (props: T) => {
  const nextStep = useMemo(() => {
    if (props.step1Progress < 100) {
      return { title: 'Finish Your Profile', link: '/candidate/profile/edit' };
    }

    if (props.step2Progress < 50) {
      return { title: 'Record VetAI Interview', link: '/candidate/profile/edit?tab=interview' };
    }

    if (props.step2Progress >= 50 && props.step3Progress < 100) {
      return { title: 'Record InterviewAI Interview', link: '/candidate/interviews' };
    }

    if (props.step3Progress < 100) {
      return { title: 'Get Hired', link: '/candidate/jobs' };
    }

    if (props.step4Progress < 100) {
      return { title: 'Add Payment Method', link: '/candidate/payment/methods' };
    }

    if (props.step4Progress === 100) {
      return { title: 'You are all set!', link: '/candidate/welcome' };
    }
  }, []);

  return (
    <div className="!w-full !h-[85px] rounded-[12px] main-gradient mt-2 text-white px-[24px] py-[16px] grid grid-cols-12">
      <div className="col-span-4">
        <p className="text-priLight3 mb-1 text-body14 font-semibold">Next Step:</p>
        <h3 className="text-h3 font-extrabold text-white  hover:underline">
          <a
            href={nextStep?.link}
            className="text-white flex items-center cursor-pointer"
          >
            {nextStep?.title}
            <i className="bi bi-box-arrow-up-right !ml-2 !text-white !font-bold" />
          </a>
        </h3>
      </div>

      <div className="col-span-8">
        <p className="text-priLight3 mb-1 text-body14 font-semibold">Progress:</p>
        <div className="flex justify-between gap-x-4">
          <div className="text-white font-bold text-body12 w-full">
            <p className="mb-0 ">
              {props.step1Progress === 100 && (
                <i className="bi bi-check-circle-fill !text-priLight3" />
              )}{' '}
              Setup Profile
            </p>
            <MiniProgressBar progress={props.step1Progress} />
          </div>
          <div className="text-white font-bold text-body12 w-full">
            <p className="mb-0 ">
              {props.step2Progress === 100 && (
                <i className="bi bi-check-circle-fill !text-priLight3" />
              )}{' '}
              Record Interview
            </p>
            <MiniProgressBar progress={props.step2Progress} />
          </div>
          <div className="text-white font-bold text-body12 w-full">
            <p className="mb-0 ">
              {props.step3Progress === 100 && (
                <i className="bi bi-check-circle-fill !text-priLight3" />
              )}{' '}
              Get Hired
            </p>
            <MiniProgressBar progress={props.step3Progress} />
          </div>
          <div className="text-white font-bold text-body12 w-full">
            <p className="mb-0 ">
              {props.step4Progress === 100 && (
                <i className="bi bi-check-circle-fill !text-priLight3" />
              )}{' '}
              Add Payment Method
            </p>
            <MiniProgressBar progress={props.step4Progress} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidateBanner;
