import React, {
  FC,
  createContext,
  useContext,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  ReactNode,
  useLayoutEffect,
  useMemo,
} from 'react';
import { DefaultConfig } from './_LayoutConfig';
import {
  setLayoutIntoLocalStorage,
  getEmptyCssClasses,
  getEmptyCSSVariables,
  getEmptyHTMLAttributes,
  LayoutSetup,
} from './_LayoutSetup';
import {
  ILayout,
  ILayoutCSSVariables,
  ILayoutCSSClasses,
  ILayoutHTMLAttributes,
  LayoutType,
  ToolbarType,
} from './_Models';
import { WithChildren } from '../../helpers';
// import { Toolbar } from './Toolbar';
// import { PageTitleWrapper } from './page-title';
import { Link } from 'react-router-dom';
// import { Avatar } from '../../../../app/util/avatar';
// import { useAuth } from '../../../../app/modules/auth';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import CandidateBanner from '../components/CandidateBanner';
import { useAuth } from '../../../app/modules/auth';
import { Avatar } from '../../../app/util/avatar';
import { fetchHasUnreadNotifications } from '../../../app/modules/notifications/FetchNotifications';
import { CandidateProfileModel } from '../../../app/modules/candidate/profile/Models';
import axios from 'axios';
import { Config } from '../../../config';
import { useQuery } from 'react-query';
import { PaymentMethod } from '../../../app/modules/candidate/payment/Models';

export interface LayoutContextModel {
  config: ILayout;
  classes: ILayoutCSSClasses;
  attributes: ILayoutHTMLAttributes;
  cssVariables: ILayoutCSSVariables;
  setLayout: (config: LayoutSetup) => void;
  setLayoutType: (layoutType: LayoutType) => void;
  setToolbarType: (toolbarType: ToolbarType) => void;
  setToolbarButtons: Dispatch<SetStateAction<ReactNode>>;
  toolbarButtons: ReactNode;
}

const LayoutContext = createContext<LayoutContextModel>({
  config: DefaultConfig,
  classes: getEmptyCssClasses(),
  attributes: getEmptyHTMLAttributes(),
  cssVariables: getEmptyCSSVariables(),
  setLayout: (config: LayoutSetup) => {},
  setLayoutType: (layoutType: LayoutType) => {},
  setToolbarType: (toolbarType: ToolbarType) => {},
  setToolbarButtons: () => <></>,
  toolbarButtons: <></>,
});

const enableSplashScreen = () => {
  const splashScreen = document.getElementById('splash-screen');
  if (splashScreen) {
    splashScreen.classList.remove('hidden');
  }
};

const disableSplashScreen = () => {
  const splashScreen = document.getElementById('splash-screen');
  if (splashScreen) {
    splashScreen.classList.add('hidden');
  }
};

const LayoutProvider: FC<WithChildren> = ({ children }) => {
  const [config, setConfig] = useState(LayoutSetup.config);
  const [classes, setClasses] = useState(LayoutSetup.classes);
  const [attributes, setAttributes] = useState(LayoutSetup.attributes);
  const [cssVariables, setCSSVariables] = useState(LayoutSetup.cssVariables);
  const [toolbarButtons, setToolbarButtons] = useState<any>(<></>);

  const setLayout = (_themeConfig: Partial<ILayout>) => {
    enableSplashScreen();
    const bodyClasses = Array.from(document.body.classList);
    bodyClasses.forEach((cl) => document.body.classList.remove(cl));
    const updatedConfig = LayoutSetup.updatePartialConfig(_themeConfig);
    setConfig(Object.assign({}, updatedConfig));
    setClasses(LayoutSetup.classes);
    setAttributes(LayoutSetup.attributes);
    setCSSVariables(LayoutSetup.cssVariables);
    setTimeout(() => {
      disableSplashScreen();
    }, 500);
  };

  const setToolbarType = (toolbarType: ToolbarType) => {
    const updatedConfig = { ...config };
    if (updatedConfig.app?.toolbar) {
      updatedConfig.app.toolbar.layout = toolbarType;
    }

    setLayoutIntoLocalStorage(updatedConfig);
    window.location.reload();
  };

  const setLayoutType = (layoutType: LayoutType) => {
    const updatedLayout = { ...config, layoutType };
    setLayoutIntoLocalStorage(updatedLayout);
    window.location.reload();
  };

  const value: LayoutContextModel = {
    config,
    classes,
    attributes,
    cssVariables,
    setLayout,
    setLayoutType,
    setToolbarType,
    setToolbarButtons,
    toolbarButtons,
  };

  useEffect(() => {
    disableSplashScreen();
  }, []);

  return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>;
};
const LayoutToolbarButtons: FC<WithChildren> = ({ children }) => {
  const auth = useAuth();
  const { currentUser } = auth;

  if (currentUser?.user_type === 'customer') {
    CustomerLayoutToolbarButtons({ children: children });
  } else {
    CandidateLayoutToolbarButtons({ children: children });
  }

  return null;
};

const CustomerLayoutToolbarButtons: FC<WithChildren> = ({ children }) => {
  const auth = useAuth();

  const { setToolbarButtons } = useContext(LayoutContext);
  const [unread, setUnread] = useState(false);

  useEffect(() => {
    async function checkUnreadNotifications() {
      const response = await fetchHasUnreadNotifications();
      setUnread(response);
    }

    checkUnreadNotifications();
  }, [unread]);

  const HOCToolbar = () => {
    return (
      <header className="flex flex-col !w-full">
        <section className="flex items-start justify-between">
          <div>{children}</div>
          <div className={'d-flex'}>
            <OverlayTrigger
              delay={{ hide: 200, show: 100 }}
              overlay={(props) => (
                <Tooltip className={'tooltip'} {...props}>
                  Notifications
                </Tooltip>
              )}
              placement="bottom"
            >
              <Link
                title={'Notifications'}
                to={'/notifications'}
                id={'notifications_wrapper'}
                className={'btn ml-3 p-1 ms-3 me-3 position-relative'}
              >
                <i className="bi bi-bell !text-2xl !text-priBlack" />
                {unread && (
                  <span className="position-absolute top-30 start-70 translate-middle p-1 bg-danger border border-light rounded-circle" />
                )}
              </Link>
            </OverlayTrigger>
            <OverlayTrigger
              delay={{ hide: 200, show: 100 }}
              overlay={(props) => (
                <Tooltip className={'tooltip'} {...props}>
                  Account
                </Tooltip>
              )}
              placement="bottom"
            >
              <Link to={'/account'} className="btn ml-0 me-0 pri-border !rounded-full !p-[2px]">
                <Avatar
                  user={{
                    firstName: auth.currentUser?.first_name as string,
                    lastName: auth.currentUser?.last_name as string,
                    email: auth.currentUser?.email as string,
                  }}
                />
              </Link>
            </OverlayTrigger>
          </div>
        </section>
      </header>
    );
  };

  useLayoutEffect(() => {
    setToolbarButtons(HOCToolbar || <></>);
    return () => {
      setToolbarButtons(<></>);
    };
  }, [setToolbarButtons, children]);

  return null;
};

const CandidateLayoutToolbarButtons: FC<WithChildren> = ({ children }) => {
  const { setToolbarButtons } = useContext(LayoutContext);

  const auth = useAuth();
  const { currentUser } = auth;
  const [unread, setUnread] = useState(false);
  // const { config, classes } = useLayout();

  useEffect(() => {
    async function checkUnreadNotifications() {
      const response = await fetchHasUnreadNotifications();
      setUnread(response);
    }

    checkUnreadNotifications();
  }, [unread]);

  const fetchProfile = async (): Promise<CandidateProfileModel> => {
    const response = await axios.get(`${Config.api_url}/candidate/profile`);
    return await response.data.value;
  };

  const { data: profileData } = useQuery(['candidate.profile', currentUser?.id], fetchProfile);

  const fetchBankAccount = async (): Promise<Array<PaymentMethod>> => {
    const response = await axios.get(`${Config.api_url}/candidate/payee`);
    return await response.data.value;
  };

  const { data: paymentData } = useQuery(['candidate.payee.methods'], fetchBankAccount);

  const fetchJob = async (): Promise<{ is_hired: boolean }> => {
    const response = await axios.get(`${Config.api_url}/candidate/profile/check-status`);
    return await response.data.value;
  };

  const { data: jobsData } = useQuery(['profile'], fetchJob);

  const step1Percentage = useMemo(() => {
    let currentCompletion = 0;

    if (currentUser?.profile_status?.completion_status?.bio_complete) {
      currentCompletion += 40;
    }
    if (currentUser?.profile_status?.completion_status?.skills_complete) {
      currentCompletion += 30;
    }
    if (currentUser?.profile_status?.completion_status?.unique_avatar) {
      currentCompletion += 20;
    }
    if (currentUser?.profile_status?.completion_status?.years_professional_experience_complete) {
      currentCompletion += 10;
    }

    return currentCompletion;
  }, [currentUser]);

  const step2Percentage = profileData?.profile_status?.completion_status?.vet_ai ? 50 : 5;
  const step3Percentage = jobsData && jobsData?.is_hired ? 100 : 5;
  const step4Percentage = paymentData && paymentData?.length > 0 ? 100 : 5;

  const HOCToolbar = () => {
    return (
      <header className="flex flex-col !w-full">
        <section className="flex items-start justify-between">
          <div>{children}</div>
          <div className={'d-flex'}>
            <OverlayTrigger
              delay={{ hide: 200, show: 100 }}
              overlay={(props) => (
                <Tooltip className={'tooltip'} {...props}>
                  Account
                </Tooltip>
              )}
              placement="bottom"
            >
              <Link to={'/account'} className="btn ml-0 me-0 pri-border !rounded-full !p-[2px]">
                <Avatar
                  user={{
                    firstName: auth.currentUser?.first_name as string,
                    lastName: auth.currentUser?.last_name as string,
                    email: auth.currentUser?.email as string,
                  }}
                />
              </Link>
            </OverlayTrigger>
          </div>
        </section>

        <section className="!w-full">
          <CandidateBanner
            step1Progress={step1Percentage}
            step2Progress={step2Percentage}
            step3Progress={step3Percentage}
            step4Progress={step4Percentage}
          />
        </section>
      </header>
    );
  };

  useLayoutEffect(() => {
    setToolbarButtons(HOCToolbar || <></>);
    return () => {
      setToolbarButtons(<></>);
    };
  }, [setToolbarButtons, children]);

  return null;
};

export { LayoutContext, LayoutProvider, LayoutToolbarButtons };

export function useLayout() {
  return useContext(LayoutContext);
}
