/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useAuth } from '../../auth';
import axios from 'axios';
import { Config } from '../../../../config';
import { useQuery } from 'react-query';
import { LayoutSplashScreen, LayoutToolbarButtons } from '../../../../_theme/layout/core';
import { InterviewsNav } from './components/InterviewsNav';
import { EmptyInterviews } from './components/EmptyInterviews';
import { Link } from 'react-router-dom';
import { VetAIInterviewType } from '../AiInterviews/constants';

export default function Interviews() {
  const { currentUser } = useAuth();

  const fetchInterviews = async () => {
    const response = await axios.get(
      `${Config.api_url}/candidate/ai-interview/list?candidate_id=${currentUser?.id}&include_questions=0`
    );
    return response.data.data;
  };

  const { isLoading, data } = useQuery<VetAIInterviewType[]>(
    ['candidate.ai-interview', currentUser?.id],
    fetchInterviews
  );

  if (isLoading || !data) {
    return <LayoutSplashScreen />;
  }

  return (
    <>
      <LayoutToolbarButtons>
        <div className="flex flex-col">
          <h1 className="text-h1 font-extrabold">Interviews</h1>
          <Link className="text-body14 font-medium text-lightGray2" to={`/candidate/welcome`}>
            Dashboard
          </Link>
        </div>
      </LayoutToolbarButtons>
      <div className="card mb-7">
        <InterviewsNav />
      </div>

      {data?.length === 0 ? (
        <EmptyInterviews />
      ) : (
        <div className="m-2">
          <table className={'table align-middle gs-0 gy-4 !rounded-[10px] !px-4'}>
            <thead className="bg-[#F8FAFF] border border-[#E7ECFD] !rounded-[10px] ">
              <tr className="px-4">
                <th className={'text-body12 font-semibold'}>Interview Type</th>
                <th className={'text-body12 font-semibold'}>Status</th>
                {/* <th className={'text-body12 font-semibold'}>Total Score</th> */}
                <th className={'text-body12 font-semibold'}>Reason</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((v) => (
                <>
                  <tr key={v.ai_interview_id}>
                    <td>{v.interview_type === 'vet_ai' ? 'VetAI' : 'InterviewAI'}</td>
                    <td className="!capitalize">{v.status}</td>
                    {/* <td>{v.total_score}</td> */}
                    <td>
                      {v.status === 'failed'
                        ? 'Something went wrong with this interview. You might need to record it again. Sorry for the inconveniece!'
                        : v.total_score_description}
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}
