/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import 'easymde/dist/easymde.min.css';
import toast from 'react-hot-toast';
import { useQuery } from 'react-query';
import { Config } from '../../../../config';
import { LayoutSplashScreen, LayoutToolbarButtons } from '../../../../_theme/layout/core';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useAuth } from '../../auth';
import { findTimezoneByValue, TimezoneOptions } from '../../../util/timezone';
import Select from 'react-select';
import { CountryOptions, findCountryByValue } from '../../../util/countries';
import SimpleMdeReact from 'react-simplemde-editor';
import SimpleMDE from 'easymde';
import * as DOMPurify from 'dompurify';
import { marked } from 'marked';
import { findSalaryRangeByValue, SalaryRanges } from '../../../util/salary';
import Tagable from '../../../components/Tagable';
import { findYearsExperienceByValue, YearsExperience } from '../../../util/yearsexperience';
import {
  CandidateCertificationModel,
  CandidateProfileModel,
  CandidateWorkHistoryModel,
} from './Models';
import { captureException } from '@sentry/react';
import { useSearchParams } from 'react-router-dom';
import { EditAvatar } from './components/EditAvatar';
import { ResumeParser } from './components/ResumeParser';
import { VetAIInterview } from './components/VetAI';
import { WorkHistory } from './components/WorkHistory';
import { Certification } from './components/Certification';
import { Tab, Tabs } from 'react-bootstrap';
import {
  defaultAvailabilities,
  startAvailabityOptions,
} from '../../../util/startAvailabilityOptions';

// default image
export const isDefaultProfileImageRegex =
  /https:\/\/distro-app-assets\.s3\.amazonaws\.com\/avatars\/profile_\d+\.svg/;

const styles = {
  control: (css: any) => ({
    ...css,
    boxShadow: 'none',
    backgroundColor: 'var(--distro-input-bg)',
    borderColor: 'var(--distro-input-border-color)',
    color: 'var(--distro-input-solid-color)',
    minHeight: 'calc(1.5em + 1.65rem + 2px)',
    fontSize: '1.15rem',
    padding: '0.3rem .6rem',
    borderRadius: '0.625rem',
    '&:active': {
      boxShadow: 'none',
      borderColor: 'var(--distro-input-focus-border-color)',
    },
    '&:hover': {
      boxShadow: 'none',
      borderColor: 'var(--distro-input-focus-border-color)',
    },
  }),
  valueContainer: (css: any) => ({
    ...css,
    display: 'grid',
    '&:active': {
      backgroundColor: 'none',
      borderColor: 'none',
      boxShadow: 'none',
    },
  }),
  // selected value container
  multiValue: (css: any) => {
    return {
      ...css,
      backgroundColor: '#f2f4f7',
      fontSize: '16px',
      lineHeight: '1.5',
      margin: '0 8px 0 0',
      padding: '8px 12px',
    };
  },
  // selected value remove container
  multiValueRemove: (css: any, state: any) => {
    return {
      ...css,
      backgroundColor: '#f2f4f7',
      '&:hover': {
        backgroundColor: '#f2f4f7',
      },
      marginLeft: '4px',
      marginRight: 0,
      paddingRight: 0,
    };
  },
  // selected value label
  multiValueLabel: (provided: any, state: any) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return {
      ...provided,
      opacity,
      transition,
      padding: '0 !important',
      margin: 0,
    };
  },
};

export function EditProfile() {
  const [searchParams] = useSearchParams();
  const { currentUser } = useAuth();
  const [processing, setProcessing] = useState<boolean>(false);
  const [inputData, setInputData] = useState<any>({});
  const [otherAvailability, setOtherAvailability] = useState<string>('');
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);

  const fetchCertifications = async (): Promise<Array<CandidateCertificationModel>> => {
    const response = await axios.get(`${Config.api_url}/candidate/certifications`);
    return await response.data.value;
  };

  const { data: certificatesData } = useQuery({
    queryKey: ['candidate.certifications', currentUser?.id],
    queryFn: fetchCertifications,
  });

  const fetchWorkHistory = async (): Promise<Array<CandidateWorkHistoryModel>> => {
    const response = await axios.get(`${Config.api_url}/candidate/work-history`);
    return await response.data.value;
  };

  const { data: workHistoryData } = useQuery({
    queryKey: ['candidate.workhistory', currentUser?.id],
    queryFn: fetchWorkHistory,
  });

  const wysiwygOptions = useMemo((): SimpleMDE.Options => {
    return {
      toolbar: ['bold', 'italic', '|', 'unordered-list', 'ordered-list', '|', 'preview'],
      autofocus: false,
      spellChecker: false,
      uploadImage: false,
      previewImagesInEditor: false,
      sideBySideFullscreen: false,
      status: false,
      previewRender: (plainText) => {
        plainText = plainText.replace(/(\w{3})[\w.-]+@([\w.]+\w)/, '');
        plainText = plainText.replace(
          /\b((?:[a-z][\w-]+:(?:\/{1,3}|[a-z0-9%])|www\d{0,3}[.]|[a-z0-9.\-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))/g,
          ''
        );
        return DOMPurify.sanitize(marked.parse(plainText), {
          ALLOWED_TAGS: ['b', 'em', 'strong', 'p', 'br', 'i', 'ul', 'li', 'ol'],
        });
      },
    } as SimpleMDE.Options;
  }, []);

  const handleInputUpdate = (newData: any) => {
    setHasUnsavedChanges(true);
    setInputData((existingData: any) => ({
      ...existingData,
      ...newData,
    }));
  };

  const handleSave = async () => {
    setProcessing(true);
    if (inputData.skills instanceof Array) {
      inputData.skills = inputData.skills.join(',');
    }

    if (inputData.languages instanceof Array) {
      inputData.languages = inputData.languages.join(',');
    }

    if (inputData.additional_skills instanceof Array) {
      inputData.additional_skills = inputData.additional_skills.join(',');
    }

    if (inputData.start_availability === 'other') {
      inputData.start_availability = otherAvailability;
    }

    try {
      await axios.post(`${Config.api_url}/candidate/profile`, { ...inputData });
      toast.success(`Profile Updated`);
      refetch();
      setHasUnsavedChanges(false);
    } catch (error: any) {
      let message = error.response?.data.message;
      toast.error(message);
      setInputData((prev: any) => ({ ...prev, start_availability: data?.start_availability }));
      captureException(error);
    } finally {
      setProcessing(false);
    }
  };

  const fetchProfile = async (): Promise<CandidateProfileModel> => {
    const response = await axios.get(`${Config.api_url}/candidate/profile`);
    return await response.data.value;
  };

  const { isLoading, data, isRefetching, refetch } = useQuery({
    queryKey: ['candidate.profile', currentUser?.id],
    queryFn: fetchProfile,
  });

  const handleResumeParsingComplete = useCallback(() => {
    setProcessing(true);
    const timeout = setTimeout(async function () {
      await refetch();
      setProcessing(false);
    }, 4000);

    return () => {
      clearTimeout(timeout);
    };
  }, [refetch]);

  useEffect(() => {
    if (data?.start_availability && !defaultAvailabilities.includes(data.start_availability)) {
      setOtherAvailability(data.start_availability);
      data.start_availability = 'other';
    }

    setInputData(data);
  }, [data]);

  const completion = useMemo(() => {
    let currentCompletion = 0;

    if (data?.first_name && data?.last_name) {
      currentCompletion += 10;
    }

    if (
      data?.skills &&
      data?.skills.length > 0 &&
      data?.additional_skills &&
      data?.additional_skills.length > 0
    ) {
      currentCompletion += 10;
    }

    if (!isDefaultProfileImageRegex.test(data?.avatar_url as string)) {
      currentCompletion += 10;
    }

    if (data?.years_professional_experience) {
      currentCompletion += 5;
    }

    if (data?.start_availability) {
      currentCompletion += 5;
    }

    if (data?.location) {
      currentCompletion += 10;
    }

    if (data?.timezone) {
      currentCompletion += 10;
    }

    if (data?.salary_range_in_cents) {
      currentCompletion += 5;
    }

    if (data?.headline) {
      currentCompletion += 10;
    }

    if (data?.bio) {
      currentCompletion += 10;
    }

    if (data?.languages) {
      currentCompletion += 5;
    }

    if (data?.work_history && data?.work_history?.length > 0) {
      currentCompletion += 10;
    }

    return currentCompletion;
  }, [data]);

  const isStep1Complete = useMemo(() => {
    let isComplete = true;
    const missingFields = [];

    if (!data?.first_name || !data?.last_name) {
      isComplete = false;
      missingFields.push('First Name or Last Name');
    }
    if (
      !data?.skills ||
      data?.skills.length === 0 ||
      !data?.additional_skills ||
      data?.additional_skills.length === 0
    ) {
      isComplete = false;
      missingFields.push('Skills or Additional Skills');
    }
    if (isDefaultProfileImageRegex.test(data?.avatar_url as string)) {
      isComplete = false;
      missingFields.push('Custom Profile Image');
    }
    if (!data?.years_professional_experience) {
      isComplete = false;
      missingFields.push('Years of Experience');
    }
    if (!data?.start_availability) {
      isComplete = false;
      missingFields.push('Start Availability');
    }
    if (!data?.location) {
      isComplete = false;
      missingFields.push('Location');
    }
    if (!data?.timezone) {
      isComplete = false;
      missingFields.push('Timezone');
    }
    if (!data?.salary_range_in_cents) {
      isComplete = false;
      missingFields.push('Salary Range');
    }
    if (!data?.headline) {
      isComplete = false;
      missingFields.push('Headline');
    }
    if (!data?.bio) {
      isComplete = false;
      missingFields.push('Bio');
    }
    if (!data?.languages) {
      isComplete = false;
      missingFields.push('Languages');
    }

    return { isComplete, missingFields };
  }, [
    data?.avatar_url,
    data?.additional_skills,
    data?.bio,
    data?.first_name,
    data?.headline,
    data?.languages,
    data?.last_name,
    data?.location,
    data?.salary_range_in_cents,
    data?.skills,
    data?.start_availability,
    data?.timezone,
    data?.years_professional_experience,
  ]);

  const isStep2Complete = useMemo(() => {
    let isComplete = true;
    const missingFields = [];
    if (!workHistoryData || workHistoryData.length === 0) {
      isComplete = false;
      missingFields.push('Work History');
    }

    // For now, we are not going to make cerificates mandatory

    // if (!certificatesData || certificatesData.length === 0) {
    //   return false;
    // }

    return { isComplete, missingFields };
  }, [workHistoryData]);

  const isStep3Complete = useMemo(() => {
    return currentUser?.profile_status?.completion_status?.vet_ai;
  }, [currentUser]);

  if (isRefetching || isLoading || !data) {
    return <LayoutSplashScreen />;
  }

  return (
    <>
      <LayoutToolbarButtons>
        <div className="flex items-center gap-8">
          <div className="flex flex-col">
            <h1 className="text-h1 font-extrabold">Profile Update</h1>
            <p className="text-body14 font-medium text-pri">
              {completion}% <span className="text-priDark">Complete!</span>
            </p>
          </div>
          <button
            className="main-button main-gradient !text-white"
            disabled={processing}
            onClick={handleSave}
          >
            Save Changes
          </button>
        </div>
      </LayoutToolbarButtons>

      <div className={'col-xl-12 mt-6 mx-auto'}>
        <ResumeParser
          disabled={!data.has_resume_parsed && !data.video}
          onProcessing={() => setProcessing(true)}
          processing={processing}
          onProcessingComplete={handleResumeParsingComplete}
          allowUsage={true}
        />
      </div>

      <Tabs
        defaultActiveKey={searchParams.get('tab') || 'profile'}
        justify
        className="!border-none"
      >
        <Tab
          className="!border-none"
          eventKey="profile"
          title={
            <div className="flex flex-col justify-center items-center">
              <span
                className={`size-[40px] rounded-full 
                  flex justify-center items-center text-body14 font-bold ${
                    isStep1Complete.isComplete && 'main-gradient !text-white'
                  }`}
                style={{ border: '2px solid #9309FF' }}
              >
                1
              </span>
              <span className="text-body15 font-bold">Setup Profile</span>
            </div>
          }
        >
          <div className="card">
            <div className="card-body">
              <div className="row mb-8">
                {/* <div className="col-lg-4 ">
                  <p className=" mt-5 mb-0 text-gray-600 fw-bold required">Profile Image</p>
                </div> */}
                <EditAvatar
                  processing={processing}
                  key={data.avatar_url}
                  user_id={currentUser?.id as string}
                  existing_avatar_url={data.avatar_url}
                  // onChange={(imageLocation) => handleInputUpdate({ avatar_url: imageLocation })}
                  refetch={refetch}
                  is_default={data.avatar_is_default}
                />
              </div>

              <div className="row mb-8">
                <div className="col-lg-6 ">
                  <p className=" required text-body14 font-bold text-priBlack m-0">Full Name</p>
                  <p className="text-body12 text-darkGray m-0 p-0 invisible">{' hidden '}</p>
                  <input
                    type="text"
                    defaultValue={data.full_name}
                    onChange={(e) =>
                      handleInputUpdate({
                        full_name: e.target.value,
                      })
                    }
                    className="form-control required"
                    required
                  />
                </div>
                <div className="col-lg-6">
                  <p className="required text-body14 font-bold text-priBlack m-0">Salary Range</p>
                  <p className="text-body12 text-darkGray m-0 p-0">
                    Monthly salary request in USD for full-time work
                  </p>
                  <Select
                    defaultValue={findSalaryRangeByValue(data?.salary_range_in_cents?.toString())}
                    styles={styles}
                    placeholder="Select Required Monthly Salary Range ($USD)"
                    isClearable={true}
                    name="salary_range_in_cents"
                    onChange={(e) =>
                      handleInputUpdate({
                        salary_range_in_cents: e?.value,
                      })
                    }
                    isMulti={false}
                    options={SalaryRanges}
                  />
                </div>
              </div>

              <div className="row mb-8">
                <div className="col-lg-6">
                  <p className="required text-body14 font-bold text-priBlack m-0">Headline</p>
                  <p className="text-body12 text-darkGray m-0 p-0">
                    Add a blurb about you so companies quickly know something about you
                  </p>
                  <input
                    type="text"
                    maxLength={30}
                    defaultValue={data.headline}
                    placeholder={'e.g. Senior UI/UX Developer | Graphic Expert'}
                    onChange={(e) =>
                      handleInputUpdate({
                        headline: e.target.value,
                      })
                    }
                    className="form-control"
                  />
                </div>
                <div className="col-lg-6">
                  <p className="required  text-body14 font-bold text-priBlack m-0">Country</p>
                  <p className="text-body12 text-darkGray m-0 p-0 invisible">{' hidden '}</p>
                  <Select
                    defaultValue={findCountryByValue(data.location_by_country as string)}
                    styles={styles}
                    placeholder="Select a country"
                    isClearable={true}
                    name="country"
                    isMulti={false}
                    options={CountryOptions()}
                    onChange={(e) =>
                      handleInputUpdate({
                        location: e?.value,
                      })
                    }
                  />
                </div>
              </div>

              <div className="row mb-8">
                <div className="col-lg-6">
                  <p className="required text-body14 font-bold text-priBlack m-0">Timezone</p>
                  <p className="text-body12 text-darkGray m-0 p-0 invisible">{' hidden '}</p>
                  <Select
                    defaultValue={findTimezoneByValue(data.timezone as string)}
                    styles={styles}
                    placeholder="Select a timezone"
                    isClearable={true}
                    name="timezone"
                    onChange={(e) =>
                      handleInputUpdate({
                        timezone: e?.value,
                      })
                    }
                    isMulti={false}
                    options={TimezoneOptions(false)}
                  />
                </div>
                <div className="col-lg-6">
                  <p className="required text-body14 font-bold text-priBlack m-0">
                    Professional Years Experience
                  </p>
                  <p className="text-body12 text-darkGray m-0 p-0 invisible">{' hidden '}</p>
                  <Select
                    defaultValue={findYearsExperienceByValue(data.years_professional_experience)}
                    styles={styles}
                    placeholder="Select Professional Years Experience"
                    isClearable={true}
                    name="years_professional_experience"
                    onChange={(e) =>
                      handleInputUpdate({
                        years_professional_experience: e?.value,
                      })
                    }
                    isMulti={false}
                    options={YearsExperience}
                  />
                </div>
              </div>

              <div className="row mb-8">
                <div className="col-lg-6">
                  <p className="required text-body14 font-bold text-priBlack m-0">Availability</p>
                  <p className="text-body12 text-darkGray m-0 p-0">
                    When would you be able to start a new role?
                  </p>
                  <Select
                    defaultValue={
                      !data.start_availability
                        ? undefined
                        : defaultAvailabilities.includes(data.start_availability)
                        ? startAvailabityOptions.find(
                            (item) => item.value === data.start_availability
                          )
                        : { value: 'other', label: 'Other' }
                    }
                    styles={styles}
                    placeholder="Select your Availability"
                    isClearable={true}
                    name="start_availability"
                    onChange={(e) =>
                      handleInputUpdate({
                        start_availability: e?.value,
                      })
                    }
                    isMulti={false}
                    options={startAvailabityOptions}
                  />
                </div>
                {inputData.start_availability === 'other' && (
                  <div className="col-lg-6">
                    <p className="required text-body14 font-bold text-priBlack m-0">
                      Specify your Availability
                    </p>
                    <p className="text-body12 text-darkGray m-0 p-0 invisible">{' hidden '}</p>
                    <input
                      type="text"
                      defaultValue={otherAvailability}
                      onChange={(e) => setOtherAvailability(e.target.value)}
                      className="form-control required"
                      required
                    />
                  </div>
                )}
              </div>

              <div className="row mb-8">
                <div className="col-lg-12">
                  <p className="required text-body14 font-bold text-priBlack m-0">
                    Short Biography
                  </p>
                  <p className="text-body12 text-darkGray m-0 p-0">
                    Tell us about yourself. What do you enjoy working on?
                  </p>
                  <SimpleMdeReact
                    value={inputData.bio as string}
                    defaultValue={data.bio}
                    options={wysiwygOptions}
                    onChange={(e) =>
                      handleInputUpdate({
                        bio: e,
                      })
                    }
                  />
                </div>
              </div>

              <div className="row mb-8">
                <div className="col-lg-6">
                  <label className="required text-body14 font-bold text-priBlack m-0">
                    <span className="">Main Skills</span>
                  </label>
                  <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement="bottom"
                    overlay={
                      <Tooltip className="text-body12">
                        Please use the 'Main Skills' section to highlight the key skills you've
                        developed throughout your experiences. This should reflect the areas where
                        you have the most expertise and achievements, helping us better understand
                        your strengths.
                      </Tooltip>
                    }
                  >
                    <i className="bi bi-info-circle-fill !ml-2 !text-lightGray2"></i>
                  </OverlayTrigger>
                  <p className="text-body12 text-darkGray m-0 p-0">
                    Use the <u>comma</u> or <u>return</u> key to add a skill
                  </p>
                  <Tagable
                    placeholder={'e.g. React, Product Management, Agile, Kubernetes'}
                    defaultValues={data.skills || []}
                    handleChange={(value) =>
                      handleInputUpdate({
                        skills: value,
                      })
                    }
                  />
                  <small className={'text-gray-600'}>Max 5</small>
                </div>

                <div className="col-lg-6">
                  <p className="required text-body14 font-bold text-priBlack m-0">
                    Written and Spoken Languages
                  </p>
                  <p className=" text-body12 text-darkGray m-0 p-0">
                    Provide a list of languages you are proficient in, both written and spoken.
                  </p>
                  <Tagable
                    placeholder={'e.g. English, French, Chinese, Spanish'}
                    defaultValues={data.languages || []}
                    handleChange={(value) =>
                      handleInputUpdate({
                        languages: value,
                      })
                    }
                  />
                </div>

                <div className="col-lg-6 mt-2">
                  <label className="required text-body14 font-bold text-priBlack m-0">
                    <span className="">Additional Skills</span>
                  </label>
                  <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement="bottom"
                    overlay={
                      <Tooltip className="text-body12">
                        Please use the 'Additional Skills' section to highlight other relevant
                        skills you've developed. These complement your main expertise and provide a
                        broader understanding of your abilities.
                      </Tooltip>
                    }
                  >
                    <i className="bi bi-info-circle-fill !ml-2 !text-lightGray2"></i>
                  </OverlayTrigger>
                  <p className="text-body12 text-darkGray m-0 p-0">
                    Use the <u>comma</u> or <u>return</u> key to add a skill
                  </p>
                  <Tagable
                    placeholder={'e.g. React, Product Management, Agile, Kubernetes'}
                    defaultValues={data.additional_skills || []}
                    handleChange={(value) =>
                      handleInputUpdate({
                        additional_skills: value,
                      })
                    }
                  />
                  <small className={'text-gray-600'}>Max 20</small>
                </div>
              </div>
            </div>
          </div>
        </Tab>

        <Tab
          eventKey="history"
          title={
            <div className="flex flex-col justify-center items-center">
              <span
                className={`size-[40px] rounded-full 
                  flex justify-center items-center text-body14 font-bold ${
                    isStep2Complete.isComplete && 'main-gradient !text-white'
                  }`}
                style={{ border: '2px solid #9309FF' }}
              >
                2
              </span>
              <span className="text-body15 font-bold">Work History & Certificates</span>
            </div>
          }
        >
          <WorkHistory />
          <Certification />
        </Tab>

        <Tab
          eventKey="interview"
          title={
            <div className="flex flex-col justify-center items-center">
              <span
                className={`size-[40px] rounded-full 
                  flex justify-center items-center text-body14 font-bold ${
                    isStep3Complete && 'main-gradient !text-white'
                  }`}
                style={{ border: '2px solid #9309FF' }}
              >
                3
              </span>
              <span className="text-body15 font-bold">Interview</span>
            </div>
          }
        >
          <VetAIInterview
            userId={data.user_id}
            isProfileComplete={{
              isComplete: isStep1Complete.isComplete && isStep2Complete.isComplete,
              missingFields: [...isStep1Complete.missingFields, ...isStep2Complete.missingFields],
            }}
          />
        </Tab>
      </Tabs>

      <div
        className={`transition-all ${
          hasUnsavedChanges ? 'bottom-4' : '-bottom-40'
        } fixed left-[50%]  bg-priBlack shadow-xl rounded-[12px] p-4 flex items-center space-x-4 z-50`}
      >
        <p className="text-body14 text-lightGray mb-0">
          {processing ? 'Saving Profile...' : 'You have unsaved changes'}
        </p>
        {!processing && (
          <button
            className="main-gradient !rounded-[6px] !py-[4px] !px-[8px] !text-body13 !font-bold hover:!opacity-80 !text-white disabled:!opacity-35"
            disabled={processing}
            onClick={handleSave}
          >
            Save Changes
          </button>
        )}
      </div>
    </>
  );
}
