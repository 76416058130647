import React from 'react';
import { CandidateCertificationModel } from '../Models';
import Moment from 'react-moment';

export interface CertificationProps {
  data: CandidateCertificationModel;
}

export const CertificationItem: React.FC<CertificationProps> = (props: CertificationProps) => {
  return (
    <>
      <div className={'p-3 rounded-4 bg-priLight00 flex'}>
        <div className={'col-sm-2 fs-3 '}>
          <span className="badge">
            <i className="bi bi-award !text-pri !text-[24px]"></i>
          </span>
        </div>
        <div className={'col-sm-8 ps-0 ms-1'}>
          <p className={'m-0 p-0 text-body12 font-bold'}>{props.data.name}</p>
          <small className={'text-darkGray m-0 p-0'}>
            {props.data.date_acquired && (
              <Moment format={'YYYY'}>{props.data.date_acquired}</Moment>
            )}
          </small>
        </div>
      </div>
    </>
  );
};
