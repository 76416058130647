import React, { useState } from 'react';
import { CandidateWorkHistoryModel } from '../Models';
import Moment from 'react-moment';
import moment from 'moment';
import clsx from 'clsx';

export interface WorkHistoryProps {
  data: CandidateWorkHistoryModel;
}

export const WorkHistoryItem: React.FC<WorkHistoryProps> = (props: WorkHistoryProps) => {
  const [toggleDescription, setToggleDescription] = useState<boolean>(false);

  const displayWorkHistoryDiff = (startDate: string, endDate: string | null) => {
    return diffYMDHMS(startDate, endDate);
  };

  const diffInMonthsOrYears = (date1: string, date2: string | null): number => {
    const date_1 = moment(date1);
    const date_2 = moment(date2 ? date2 : moment.now());
    let years = Math.abs(date_1.diff(date_2, 'year'));
    date_2.add(years, 'years');

    let months = Math.abs(date_1.diff(date_2, 'months'));
    date_2.add(months, 'months');

    if (years > 0) {
      return years;
    }

    return years === 0 ? months : 0;
  };

  const diffYMDHMS = (date1: string, date2: string | null) => {
    const date_1 = moment(date1);
    const date_2 = moment(date2 ? date2 : moment.now());
    let years = date_1.diff(date_2, 'year');
    date_2.add(years, 'years');

    let months = date_1.diff(date_2, 'months');
    date_2.add(months, 'months');

    const year = Math.abs(years);
    const month = Math.abs(months);

    if (years === 0) {
      return `${month} Months`;
    }

    if (months === 0) {
      return `${year} Years`;
    }

    return `${year} Years ${month} Months`;
  };

  return (
    <div className={`border rounded-[10px] mb-2 p-4 ${toggleDescription && 'bg-lightGray'}`}>
      <div className={'row flex items-center justify-start'}>
        <div className={'col-sm-2 w-full !h-full flex !items-start justify-center'}>
          <i className="bi bi-briefcase-fill !text-priDark !text-[32px]"></i>
        </div>

        <div className={'col-sm-10 ps-0 position-relative'}>
          <small className="">
            <Moment format={'YYYY'}>{props.data.start_date}</Moment>
            {' -'}
            <span className={'ml-8 ms-2'}>
              {!props.data.is_current && (
                <Moment format={'YYYY'}>{props.data.end_date as string}</Moment>
              )}
              {props.data.is_current && 'Present'}
              {diffInMonthsOrYears(props.data.start_date, props.data.end_date) > 0 && (
                <span className={'ms-2 text-darkGray'}>
                  ({displayWorkHistoryDiff(props.data.start_date, props.data.end_date)})
                </span>
              )}
            </span>
          </small>

          <h2 className="!text-body14 !font-medium m-0">{props.data.title}</h2>

          <p className={'!text-body14 !font-normal text-priBlack mb-0'}>
            <span className={''}>{props.data.company_name}</span>
          </p>

          <button
            type={'button'}
            onClick={() => setToggleDescription(!toggleDescription)}
            className={'btn position-absolute top-0 end-0 translate-top'}
          >
            <span className="badge rounded-pill">
              <i
                className={clsx(
                  { 'fa-chevron-down': !toggleDescription },
                  { 'fa-chevron-up': toggleDescription },
                  { 'me-0 pe-0 fa-solid': true }
                )}
              ></i>
            </span>
          </button>
          {toggleDescription && <p className={'fs-6 mt-2'}>{props.data.description}</p>}
        </div>
      </div>
    </div>
  );
};
